
import { computed, defineComponent, ref, nextTick } from 'vue'
import type { PropType } from 'vue'
import useModel from '@/hooks/useModel'
import clickOutside from '@/views/Planning/directive/clickOutside'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    },
    event: {
      type: String as PropType<'click'|'dblclick'>,
      default: 'click'
    },
    formator: {
      type: Function as PropType<(text: string) => string >,
      requried: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    clickOutside
  },
  emits: [
    'click-outside',
    'update:value'
  ],
  setup(props, {emit, attrs}) {
    const valueProp = useModel(props, 'value')
    const inEditting = ref(false)
    const inputComponent = ref()

    const handleClick = () => {
      if (!props.disabled) {
        inEditting.value = true
        nextTick(() => {
          inputComponent.value?.focus()
        })
      }
    }

    const handleClickOutSide = (event: Event) => {
      if (inEditting.value) {
        inEditting.value = false
        emit('click-outside', event)
      }
    }

    const text = computed(() => {
      if (props.formator) {
        return props.formator(props.value)
      } else {
        return props.value
      }
    })

    const attrsValue = computed(() => {
      return Object.assign({}, attrs, {class: undefined})
    })

    return {
      inputComponent,
      handleClick,
      handleClickOutSide,
      valueProp,
      inEditting,
      text,
      attrsValue
    }
  }
})
