
import { defineComponent, ref, computed, provide, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { StateStep } from '@/views/Planning/type'
import BasicSetting from '@/views/Planning/BasicSetting.vue'
import DataPreparation from '@/views/Planning/DataPreparation.vue'
import PlanningSimulation from '@/views/Planning/PlanningSimulation.vue'
import FinalView from '@/views/Planning/FinalView.vue'

export const stateStepKey = Symbol()
export const makeKey = Symbol()
export const kufriKey = Symbol()
export const planningIdKey = Symbol()
export const scenarioKey = Symbol()
export const scenarioIdKey = Symbol()
export const simulationYearKey = Symbol()
export const typePlanningOrSimulationKey = Symbol()
export const createMonthKey = Symbol()
export const currentKufriKey = Symbol()
export const planningYearKey = Symbol()

export default defineComponent({
  components: {
    BasicSetting,
    DataPreparation,
    PlanningSimulation,
    FinalView
  },
  setup() {
    const stateStep = ref<StateStep>(StateStep.BasicSetting)
    const { t } = useI18n()
    const enableStateStep = (s: StateStep) => {
      const stateStepLine = [
        StateStep.BasicSetting,
        StateStep.Preparation,
        StateStep.PlanningSimulation,
        StateStep.Reporting
      ]
      return stateStepLine.indexOf(s) <= stateStepLine.indexOf(stateStep.value)
    }

    const make = ref<string>()
    const kufri = ref<string>()
    const planningId = ref<number>()
    const scenario = ref<string>()
    const scenarioId = ref<number>()
    const simulationYear = ref<string>()
    const typePlanningOrSimulation = ref<string>()
    const createMonth = ref<string>()
    const currentKufri = ref<string>()
    const planningYear = ref<string>()

    provide(stateStepKey, stateStep)
    provide(makeKey, make)
    provide(kufriKey, kufri)
    provide(planningIdKey, planningId)
    provide(scenarioKey, scenario)
    provide(scenarioIdKey, scenarioId)
    provide(simulationYearKey, simulationYear)
    provide(typePlanningOrSimulationKey, typePlanningOrSimulation)
    provide(createMonthKey, createMonth)
    provide(currentKufriKey, currentKufri)
    provide(planningYearKey, planningYear)

    const dataPreShow = ref<boolean>(true)
    const stepSecondText = ref<string>('Planning & Simulation')

    const breadcrumbData = computed(() => {
      switch (stateStep.value) {
        case StateStep.BasicSetting:
          return [
            { name: t('planning.homepage') }
          ]
        case StateStep.Preparation:
          if (typePlanningOrSimulation.value === 'simulation') {
            return [
              { name: make.value },
              { name: 'Simulation' },
              { name: scenario.value }
            ]
          }
          return [
            { name: make.value },
            { name: scenario.value }
          ]
        case StateStep.PlanningSimulation:
          if (typePlanningOrSimulation.value === 'simulation') {
            return [
              { name: make.value },
              { name: 'Simulation' },
              { name: scenario.value }
            ]
          }
          return [
            { name: make.value },
            { name: scenario.value }
          ]
        case StateStep.Reporting:
          if (typePlanningOrSimulation.value === 'simulation') {
            return [
              { name: make.value },
              { name: 'Simulation' },
              { name: scenario.value }
            ]
          }
          return [
            { name: make.value },
            { name: scenario.value }
          ]
        default: 
          return [] 
      }
    })

    const handleClickSimulation = (type: string) => {
      if (type === 'simulation') {
        dataPreShow.value = false
        stepSecondText.value = 'Generate Simulation'
      } else {
        dataPreShow.value = true
        stepSecondText.value = 'Planning & Simulation'
      }
      
    }

    watch(stateStep, (value) => {
      if (value === 'BasicSetting') {
        dataPreShow.value = true
        stepSecondText.value = 'Planning & Simulation'
      }
    })
    
    return {
      dataPreShow,
      stepSecondText,
      stateStep,
      StateStep,
      breadcrumbData,
      enableStateStep,
      handleClickSimulation
    }
  },
})
