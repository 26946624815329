
import { defineComponent, ref, reactive, h, computed, watch } from 'vue'
import Decimal from '@/utils/closing/decimal'
import FeatureYear from './DealerStock/FeatureYear.vue'
import CurrentYear from './DealerStock/CurrentYear.vue'
import { getCurrentDealerStock, resetCurrent } from '@/API/planning'
export default defineComponent({
  components: {
    FeatureYear,
    CurrentYear
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  setup(props, context) {
    const currentYearTableData = ref<any[]>([])
    const isFutureYear = ref<boolean>(false)
    const init = () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }
      const params = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }

      const formatNumber = (value: any, round: number, percent: boolean) => {
        if (percent) {
          return value || value == 0 ? new Decimal(value ?? '0').round(round) + '%' : null
        } else {
          return value || value == 0 ? new Decimal(value ?? '0').round(round) : null
        }        
      } 
      getCurrentDealerStock(params).then(res => {
        let index = 0
        res.data?.forEach((item: any) => {
          item.id = index
          index ++
          item.openingBalance = formatNumber(item.openingBalance, 0, false)
          item.stockAdjustment = formatNumber(item.stockAdjustment, 0, false)
          if (item.march) {
            item.march.avgSi = formatNumber(item.march?.avgSi, 2, true)
            item.march.originAdjAvgSi = item.march?.adjAvgSi
            // item.march.adjAvgSi = formatNumber(item.march?.adjAvgSi, 2, false)
            item.march.movement = formatNumber(item.march?.movement, 0, false)
            item.march.stockAdjustment = formatNumber(item.march.stockAdjustment, 0, false)
            item.march.dsTarget = formatNumber(item.march.dsTarget, 0, false)
            item.march.absAmount = formatNumber(item.march.absAmount, 0, false)
            item.march.llpPercentage = formatNumber(item.march?.llpPercentage, 2, true)
          } else {
            item.march = {
              originAdjAvgSi: null,
              adjAvgSi: null,
              movement: null,
              stockAdjustment: null,
              dsTarget: null
            }
          }

          if (item.june) {
            item.june.avgSi = formatNumber(item.june?.avgSi, 2, true)
            item.june.originAdjAvgSi = item.june?.adjAvgSi
            // item.june.adjAvgSi = formatNumber(item.june?.adjAvgSi, 2, false)
            item.june.movement = formatNumber(item.june?.movement, 0, false)
            item.june.stockAdjustment = formatNumber(item.june.stockAdjustment, 0, false)
            item.june.dsTarget = formatNumber(item.june.dsTarget, 0, false)
            item.june.absAmount = formatNumber(item.june.absAmount, 0, false)
            item.june.llpPercentage = formatNumber(item.june?.llpPercentage, 2, true)
          } else {
            item.june = {
              originAdjAvgSi: null,
              adjAvgSi: null,
              movement: null,
              stockAdjustment: null,
              dsTarget: null
            }
          }

          if (item.september) {
            item.september.avgSi = formatNumber(item.september?.avgSi, 2, true)
            item.september.originAdjAvgSi = item.september?.adjAvgSi
            // item.september.adjAvgSi = formatNumber(item.september?.adjAvgSi, 2, false)
            item.september.movement = formatNumber(item.september?.movement, 0, false)
            item.september.stockAdjustment = formatNumber(item.september.stockAdjustment, 0, false)
            item.september.dsTarget = formatNumber(item.september.dsTarget, 0, false)
            item.september.absAmount = formatNumber(item.september.absAmount, 0, false)
            item.september.llpPercentage = formatNumber(item.september?.llpPercentage, 2, true)
          } else {
            item.september = {
              originAdjAvgSi: null,
              adjAvgSi: null,
              movement: null,
              stockAdjustment: null,
              dsTarget: null
            }
          }

          if (item.december) {
            item.december.avgSi = formatNumber(item.december?.avgSi, 2, true)
            item.december.originAdjAvgSi = item.december?.adjAvgSi
            // item.december.adjAvgSi = formatNumber(item.december?.adjAvgSi, 2, false)
            item.december.movement = formatNumber(item.december?.movement, 0, false)
            item.december.stockAdjustment = formatNumber(item.december.stockAdjustment, 0, false)
            item.december.dsTarget = formatNumber(item.december.dsTarget, 0, false)
            item.december.absAmount = formatNumber(item.december.absAmount, 0, false)
            item.december.llpPercentage = formatNumber(item.december?.llpPercentage, 2, true)
          } else {
            item.december = {
              originAdjAvgSi: null,
              adjAvgSi: null,
              movement: null,
              stockAdjustment: null,
              dsTarget: null
            }
          }
        })
        currentYearTableData.value = res.data

      })
      // getfutureDealerStock(params).then(res => {
      //   futureYearTableData.value = res.data
      // })
    }

    watch([() => props.kufriVersion, () => props.planningId, () => props.year], ([kufriVersionValue, planningIdValue, yearValue]) => {
      if (kufriVersionValue && planningIdValue) {
        init()
      }
      if (yearValue) {
        const date = new Date()
        const currentYear: any = date .getFullYear()
        if (yearValue > currentYear) {
          isFutureYear.value = true
        } else {
          isFutureYear.value = false
        }
      }
    }, {
      immediate: true
    })

    const handleUpdateData = () => {
      init()
      context.emit('save-data')
    }
    const handleSearchByTargrtId = (targetId: any) => {
      const params = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion,
        targetId
      }

      const formatNumber = (value: any, round: number, percent: boolean) => {
        if (percent) {
          return value || value == 0 ? new Decimal(value ?? '0').round(round) + '%' : null
        } else {
          return value || value == 0 ? new Decimal(value ?? '0').round(round) : null
        }        
      } 
      resetCurrent(params).then((res: any) => {
        init()
      })
    }

    return {
      currentYearTableData,
      isFutureYear,
      handleUpdateData,
      handleSearchByTargrtId
    }
  }
})
