import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2a1b70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-selector-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkable_tag = _resolveComponent("a-checkable-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, (data) => {
      return (_openBlock(), _createBlock(_component_a_checkable_tag, {
        key: _ctx.getValue(data),
        checked: _ctx.isChecked(data),
        onChange: () => _ctx.handleChange(data)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {
            text: _ctx.getTitle(data)
          }, () => [
            _createTextVNode(_toDisplayString(_ctx.getTitle(data)), 1)
          ], true)
        ]),
        _: 2
      }, 1032, ["checked", "onChange"]))
    }), 128))
  ]))
}