
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface OptionConf {
  title?: string;
  value?: string;
}

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<'single'|'multi'>,
      default: 'single'
    },
    dataSource: {
      type: Array as PropType<unknown[]>,
      default: () => []
    },
    value: {
      type: [Array, String] as PropType<string[]|string>
    },
    options: {
      type: Object as PropType<OptionConf>
    }
  },
  emits: [
    'update:value',
    'change'
  ],
  setup(props, {emit}) {
    const getTitle = (tag: unknown) => {
      if (props.options?.title) {
        return (tag as Record<string, unknown>)[props.options?.title] as string
      } else {
        return tag as string
      }
    }

    const getValue = (tag: unknown) => {
      if (props.options?.value) {
        return (tag as Record<string, unknown>)[props.options?.value] as string
      } else {
        return tag as string
      }
    }

    const handleChange = (tag: unknown) => {
      const changeValue = getValue(tag)
      if (props.mode === 'single') {
        emit('update:value', changeValue)
        emit('change', changeValue)
      } else {
        const value = props.value !== undefined ? [...props.value] : []
        const index = value.indexOf(changeValue)
        if (index === -1) {
          value.push(changeValue)
          emit('update:value', value)
          emit('change', value)
        } else {
          value.splice(index, 1)
          emit('update:value', value)
          emit('change', value)
        }
      }
    }

    const isChecked = (tag: unknown) => {
      const value = getValue(tag)
      if (props.mode === 'single') {
        return props.value === value
      } else {
        return props.value?.includes(value)
      }
    }

    return {
      handleChange,
      isChecked,
      getTitle,
      getValue
    }
  }
})
