
import { defineComponent, ref, reactive, h, inject, watch } from 'vue'
import ExpandMonthTable from '../components/ExpandMonthTable.vue'
import {
  getReportOverview as getReportOverviewAPI
} from '@/API/planning'
import useExpandTableTreeData from '@/hooks/planning/useExpandTableTreeData'
import useTableTreeDataKey from '@/hooks/planning/useTableTreeDataKey'

const col = ['annual', 'seasonOne', 'seasonTwo', 'seasonThree', 'seasonFour', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

const formatVolume = (volume: number) => {
  return (volume + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
}

const methodFormatList = (list: any[]) => {
  list.forEach((row: any) => {
    for (const key in row) {
      if (col.includes(key)) {
        if (row.rowName === 'WS Volume' && row[key]?.wsVolume != null) {
          row[key] = formatVolume(row[key]?.wsVolume)
        }
        if (row.rowName === 'RT Volume' && row[key]?.rtVolume != null) {
          row[key] = formatVolume(row[key]?.rtVolume)
        }
        if (row[key] && (row[key]?.finalPoint || row[key]['finalPoint'] === 0)) {
          row[key] = row[key]['finalPoint'].toFixed(2) + '%'
        }
      }
      if (key === 'children' && row[key]?.length === 0) {
        row[key] = null
      }
      if (key === 'children' && row[key]) {
        methodFormatList(row[key])
      }
    }
  })
}
export default defineComponent({
  components: {
    ExpandMonthTable
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  setup(props) {
    const rowKey = 'key'
    const tableData = ref<any[]>([])
    // 表格展开状态
    const expandModel = ref(false)

    const {expandedRowKeys, handleExpand } = useExpandTableTreeData(tableData as any, expandModel, 'children', 'key', 'finalView')
    
    const init = async () => {
      const postData = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion 
      }
      const res = await getReportOverviewAPI(postData)
      tableData.value = res.data
      useTableTreeDataKey(tableData)
      methodFormatList(tableData.value)
    }

    watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
      console.log(kufriVersionValue, planningIdValue)
      if (kufriVersionValue || planningIdValue) {
        init()
      }
    }, {
      immediate: true
    })
    return {
      rowKey,
      tableData,
      expandModel,
      expandedRowKeys,
      handleExpand
    }
  }
})
