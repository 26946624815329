
import { defineComponent, ref, reactive, h, inject, watch } from 'vue'
import { getReportStructureTree, getReportMonth } from "@/API/planning"
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import useExpandTableTreeData from '@/hooks/planning/useExpandTableTreeData'
import useTableTreeDataKey from '@/hooks/planning/useTableTreeDataKey'
import deepClone from '@/utils/deepClone'
import { forEachRecursive } from '@/utils/planning'

/**
 *
 * @param {Array} arrs 树形数据
 * @param {string} childs 树形数据子数据的属性名,常用'children'
 * @param {Array} attrArr 需要提取的公共属性数组(默认是除了childs的全部属性)
 * @returns
 */
function extractTree(arrs: any[], childs: string, attrArr: any){
  let attrList: any[] = [];
  if (!Array.isArray(arrs)) return [];
  if(typeof childs !== 'string') return [];
  if(!Array.isArray(attrArr) || Array.isArray(attrArr)&&!attrArr.length){
    attrList = Object.keys(arrs[0]);
    attrList.splice(attrList.indexOf(childs), 1)
  }else{
    attrList = attrArr;
  }
  const list: any[] = [];
  const getObj = (arr: any[])=>{
    arr.forEach(function(row){
      const obj: any = {};
      attrList.forEach(item=>{
        obj[item] = row[item];
      });
      obj.hasChild = row[childs]?.length > 0
      obj.isShowBak = row.level === 1 || row.level === 2
      obj.expandStatusBak = row.level === 1
      if(row[childs]?.length > 0){
        obj.childIds = row[childs].map((child: any) => {
          return child.id
        })
      }
      list.push(obj);
      if(row[childs]){
        getObj(row[childs]);
      }
    })
    return list;
  }
  return getObj(arrs);
}

const methodsReduce = (list: any[], key: string) => {
  const obj = {}
  list = list.reduce((cur: any[], next) => {
    obj[next[key]] ? '' : obj[next[key]] = true && cur.push(next)
    return cur
  }, [])
  return list
}

export default defineComponent({
  components: {
    TagSelector
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  setup(props) {
    const tableData = ref<any[]>([])
    let copyTableData: any[] = []

    const rowKey = 'key'

    const brand = ref<any[]>([])
    const brandOptions = ref<any[]>([])

    const columns = ref<any[]>([])
    const columnsData = ref<any[]>([])
    const structureTree = ref<any []>([])
    const monthOptions: any[] = [
      {
        value: '1',
        label: 'Jan'
      },
      {
        value: '2',
        label: 'Feb'
      },
      {
        value: '3',
        label: 'Mar'
      },
      {
        value: '4',
        label: 'Apr'
      },
      {
        value: '5',
        label: 'May'
      },
      {
        value: '6',
        label: 'Jun'
      },
      {
        value: '7',
        label: 'Jul'
      },
      {
        value: '8',
        label: 'Aug'
      },
      {
        value: '9',
        label: 'Sep'
      },
      {
        value: '10',
        label: 'Oct'
      },
      {
        value: '11',
        label: 'Nov'
      },
      {
        value: '12',
        label: 'Dec'
      }
    ]
    const chooseMonths = ref<any []>([])
    chooseMonths.value = monthOptions.map((month: any) => {
      return month.value
    })
    const chooseBrands = ref<any []>([])

    // 表格展开状态
    const expandModel = ref(false)

    
    const {expandedRowKeys = [], handleExpand } = useExpandTableTreeData(tableData as any, expandModel, 'children', 'key', 'finalView')

    // columnsData转化为columns
    const changeColnums = () => {
      columns.value = columnsData.value.filter((col: any) => {
        return col.isShow
      }).map((col: any) => {
        return {
          dataIndex: col.dataIndex,
          key: col.key,
          slots: col.slots,
          align: 'right',
          width: col.width || 120
        }
      })
      columns.value.unshift({
        title: 'Vehicle',
        dataIndex: 'rowName',
        key: 'rowName',
        fixed: 'left',
        width: 360
      })
      columns.value[1].customHeaderCell = () => {
        return {
          style: {
            paddingLeft: '30px !important'
          }
        }
      }
      columns.value[1].customCell = () => {
        return {
          style: {
            paddingLeft: '30px !important'
          }
        }
      }
    }

    // 表头展开事件
    function methodClick(currentEle: any, list: any[], callback: any){
      // 没有子节点直接返回
      if (!currentEle.hasChild) return
      // 展开状态改变
      currentEle.expandStatusBak = !currentEle.expandStatusBak
      list.forEach(item => {
        // 自节点
        if (item.parentId === currentEle.id) {
          // 一级节点关闭 二级节点全部关闭
          if (!currentEle.expandStatusBak) {
            item.expandStatusBak = false
          }
          // 子节点isShowBak树形改变（展开、收起）
          item.isShowBak = !item.isShowBak
          if (item.hasChild) {
            list.forEach(grandSon => {
              if (grandSon.parentId === item.id) {
                grandSon.isShowBak = false
              }
            })
          }
        }
      })
      columnsData.value = callback(list)
      // columns改变 获取动态表头
      changeColnums()
    }

    // tree数据格式转化
    function formatClomun(list: any[]) {
      return list.map((item: any) => {
        return {
          title: item.itemName,
          dataIndex: `cells.${item.id}`,
          key: `cells.${item.id}`,
          isShow: item.isShowBak,
          canExpand: item.hasChild,
          expandStatus: item.expandStatusBak,
          width: item.itemName.length * 8 + 50,
          slots: { 
            title: item.itemName
          },
          click: () => {
            return methodClick(item, list, formatClomun)
          }
        }
      })
    }

    // 获取树形数据 转化为表头
    const fetchGetStructure = async () => {
      const res = await getReportStructureTree()
      structureTree.value = res
      structureTree.value = extractTree(structureTree.value, 'children', ['id', 'itemName', 'parentId', 'level'])
      // 转化为表头slot数组
      columnsData.value = formatClomun(structureTree.value)
      // 转化为表头
      changeColnums()
    }
    fetchGetStructure()

    const col: any[] = structureTree.value.map((row: any) => {
      return row.id
    })

    const methodFormatList = (list: any[]) => {
      const col: any[] = structureTree.value.map((row: any) => {
        return row.id.toString()
      })
      list.forEach((row: any) => {
        for (const key in row.cells) {
          if (col.includes(key)) {
            if (row.cells[key] && (row.cells[key]['point'] || row.cells[key]['point'] === 0)) {
              row.cells[key] = row.cells[key]['point'].toFixed(2) + '%'
            }
          }
          if (row.children?.length === 0) {
            row.cells[key] = null
          }
          if (row.children) {
            methodFormatList(row.children)
          }
        }
      })
    }

    const methodsGetBrands = (tableData: any[]) => {
      const brandList: string[] = []
      forEachRecursive(tableData as any[], item => {
        console.log(item.brandName)
        brandList.push(item.brandName)
      })
      brandOptions.value = [...new Set(brandList)].filter(item => item)
      console.log(brandOptions.value)
      brand.value = brandOptions.value
    }

    const init = async (isCheckBrand = false) => {
      if (chooseMonths.value?.length === 0) {
        return tableData.value = []
      }
      const postData = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion,
        // planningId: 17,
        // kufriVersion: '2021K08',
        monthList: chooseMonths.value,
        brandList: brand.value
      }
     
      const res = await getReportMonth(postData)
      tableData.value = res.data
      useTableTreeDataKey(tableData)
      if (isCheckBrand) {
        methodsGetBrands(tableData.value)
      }   
      methodFormatList(tableData.value)
      copyTableData = deepClone(tableData.value)
    }

    watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
      if (kufriVersionValue || planningIdValue) {
        brand.value = []
        init(true)
      }
    }, {
      immediate: true
    })

    const handleChangeMonth = () => {
      init()
    }

    const handleBrandChange = () => {
      if (brand.value.length === 0) {
        return tableData.value = []
      }
      init()
      // const cloneTableData = deepClone(copyTableData)
      // cloneTableData.forEach((row: any, index: any) => {
      //   if (row.children && row.children.length > 0) {
      //     row.children = row.children.filter((child: any) => {
      //       return brand.value.includes(child.brandName)
      //     })
      //   }
      // })

      // tableData.value = cloneTableData

      // for (let i = tableData.value.length - 1; i >= 0;i--) {
      //   if (!tableData.value[i].children || tableData.value[i].children.length === 0) {
      //     tableData.value.splice(i, 1)
      //   }
      // }
    }

    return {
      rowKey,
      tableData,
      brand,
      brandOptions,
      columns,
      columnsData,
      monthOptions,
      chooseMonths,
      handleBrandChange,
      handleChangeMonth,
      expandModel,
      expandedRowKeys,
      handleExpand
    }
  }
})
