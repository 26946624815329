export enum StateStep {
  BasicSetting = 'BasicSetting',
  Preparation = 'Preparation',
  PlanningSimulation = 'PlanningSimulation',
  Reporting = 'Reporting'
}

interface PlanningSimulationStatDataItem {
  totalAmount: string;
  totalAvgSi: string;
}
export interface PlanningSimulationStatData {
  totalRtVolume: string | null;
  totalWsVolume: string | null;
  totalBidding: PlanningSimulationStatDataItem | null;
  totalCr: PlanningSimulationStatDataItem | null;
  totalDlr: PlanningSimulationStatDataItem | null;
  totalFc: PlanningSimulationStatDataItem | null;
  totalFp: PlanningSimulationStatDataItem | null;
  totalRel: PlanningSimulationStatDataItem | null;
  totalSpec: PlanningSimulationStatDataItem | null;
}

export enum PlanningSimulationTab {
  GentralRegion = 'cr',
  Fleet = "fleet",
  UC = "uc",
  FP = "fp",
  Bidding = "bidding",
  DsAdj = "dsAdj",
  SpecialAdj = "specialAdj",
  Released = "release"
}

export interface HomePageInfo {
    name: string;
    code: string;
    list: {
        planningName?: string;
        kufriMonth?: string;
        createYears: string;
    }[];
    createInfo: {
        planningName: undefined;
        kufriMonth: undefined;
        kufriOptions: string[];
        createYears: number[];
        make: string;
    };
}

export enum CbuWsCategory {
  WSLLP = 'WSLLP',
  WSVOLUME = 'WSVOLUME'
}

export type Month = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'

export type MonthEn = 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'

export interface CentralRegionInfoData {
  finalPoint: number;
}

export type CentralRegionData = {
  vehicle: string;
  brand: string;
  annual?: CentralRegionInfoData;
  children?: CentralRegionData[];
} & Partial<Record<MonthEn, CentralRegionInfoData>>

export type CentralRegionSpreadJsData = {
  level: number;
  vehicle: string;
  brand: string;
  annual?: string;
  parentRow?: number;
  childrenRow?: number[];
} & Partial<Record<MonthEn, string>>

export type FleetInfoData = {
  id?: number;
  originValue?: number|string;
  finalPoint: number|string;
  editable?: boolean;
}

export type FleetData = {
  make: string;
  brand: string;
  typeClass: string;
  impactCode: string;
  impactModel: string;
  annual: FleetInfoData;
  children?: FleetData[];
} & Partial<Record<Month, FleetInfoData>>

export type FleetSpreadJsData = {
  level: number;
  vehicle: string;
  brand: string;
  impactCode?: string;
  impactModel?: string;
  typeClass?: string;
  annual: FleetInfoData;
  parentRow?: number;
  childrenRow?: number[];
} & Record<MonthEn, FleetInfoData & { editable: boolean }>

export type GentralRegionData = {
  vehicle: string;
  fy?: string;
  children?: GentralRegionData[];
} & Partial<Record<Month, string>>

export enum AdjReleaseViewType {
  WsWolume = 'WS Volume',
  WsLLP = 'WS LLP',
  AvgSI = 'Avg.SI%',
  Amount = 'Amount w/o VAT'
}

export enum AdjReleaseInputViewType {
  AvgSI = 'Avg.SI%',
  Amount = 'Amount'
}

export enum DealerStockViewType {
  WsWolume = 'WS Volumne',
  WsLLP = 'WS LLP',
  AvgSI = 'Avg.SI%',
  Amount = 'Amount w/o VAT'
}

export enum AdjReleaseInputType {
  Allocation = 'Allocation',
  Input = 'Input'
}

export type AdjAfterClosingData = {
  vehicle: string;
  annual?: string;
  children?: AdjAfterClosingData[];
} & Partial<Record<MonthEn, string>>

export type ReleaseData = {
  vehicle: string;
  annual?: string;
  children?: ReleaseData[];
} & Partial<Record<Month, string>>

export interface FcInputTypeData {
  typeName: string;
  typeId: number;
}

export interface FcInputDataParam {
  id: number;
  actualStatus: boolean;
  finalPoint: number | null;
  impactCode: string;
  impactName: string;
  kufriVersion: string;
  month: string;
  planningId: number;
  planningTypeId: number;
  year: string;
  wsLlp: number;
  rtLlp: number;
  wsVolume: number;
  rtVolume: number;
  budget: number | null;
  typeClass: string;
  brand: string;
  msrp: number;
  topicCode: string | null;
  adjustmentNo: string | null;
  controllingPoint: number | null;
  releasePoint: number | null;
  buPoint: number | null;
  submitPoint: number | null;
}

export enum FcInputType {
  Fleet = 'Fleet',
  UC = 'UC',
  FlootPlan = 'Floot Plan',
  Bidding = 'Bidding'
}

export interface TopicData {
  code: string;
  name: string;
}

export enum AdjReleaseType {
  Adjustment = 'Adjustment',
  Release = 'Release'
}

export interface AdjStatResponseData {
  colInfoList: { colName: string; colType: 'year' | 'afterClosing' | 'topic'}[];
  rowList: {
    rowName: string;
    colList: {colName: string; colValue: string}[];
  }[];
}

export interface AdjAfterClosingResponseData {
  children: AdjAfterClosingData[];
}

export interface AdjLabelResponseData {
  id: number;
  adjustmentNo: string;
}

export interface AdjustmentDataInfo {
  wsVolume: string | number;
  wsLlp: string | number;
  finalPoint: string | number;
  wsAmount: string | number;
}

export type AdjustmentData = {
  vehicle: string;
  brand: string;
  typeClass?: string;
  impactCode?: string;
  annual?: string;
  children?: AdjustmentData[];
} & Partial<Record<MonthEn, AdjustmentDataInfo>>

export interface AdjustmentInfoResponse {
  headerId: number;
  planningTypeId: number;
  adjustmentNo: string;
  topicName: string;
  topicCode: number;
  children: AdjustmentData[];
}

export interface AdjustmentDetailDataParam {
  actualStatus: boolean;
  adjustmentNo: string;
  brand: string;
  finalPoint: number | null;
  impactCode: string;
  impactName: string;
  kufriVersion: string;
  month: string;
  msrp: number;
  planningId: number;
  planningTypeId: number;
  rtLlp?: number;
  rtVolume?: number;
  topicCode: number;
  topicName: string;
  typeClass: string;
  wsLlp?: number;
  wsVolume?: number;
  year: string;
  wsAmount?: number | null;
}

export interface WsDataNumberInfo {
  planning_id: number;
  category: 'WS LLP' | 'WS VOLUME';
  count: number;
}

export interface PlanningYearData {
  createAt: number;
  createMonth: string;
  createYear: string;
  currentKufri: string;
  id: number;
  kufriMonth: string;
  lockStatus: boolean;
  make: string;
  name: string;
  parentId: null | number;
  planningName: string;
  planningStatus: string;
  scenarioId: number;
  year: string;
}

export interface KufriInfo {
  version: string;
  counts: number;
  createAt: number;
}