import type { Ref } from 'vue'

type Data = Partial<Record<string, Data[]|unknown>>

export default function<T extends Data>(data: Ref<T[]>, childrenKey = 'children', key = 'key'): Ref<T[]> {
  let id = 1
  const setId = (data: T[]) => {
    data.forEach((item: T) => {
      (item[key] as number) = id
      id++
      if (item[childrenKey] && (item[childrenKey] as T[]).length) {
        setId(item[childrenKey] as T[])
      }
    })
  }
  setId(data.value)
  return data
}