import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b6f99ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-cell" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, _mergeProps({
    class: "data-table",
    columns: _ctx.columns
  }, _ctx.$attrs), _createSlots({ _: 2 }, [
    _renderList(_ctx.columnsLoop, (col) => {
      return {
        name: col.slots.title,
        fn: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (col.isMonth)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.year) + "-" + _toDisplayString(col.title), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(col.title), 1)),
            (col.canExpand)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  (col.expandStatus)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "expand-icon",
                        onClick: col.click
                      }, "-", 8, _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "expand-icon",
                        onClick: col.click
                      }, "+", 8, _hoisted_6))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      }
    })
  ]), 1040, ["columns"]))
}