
import { defineComponent, ref, computed, reactive } from 'vue'
import type { PropType } from 'vue'
import moment from 'moment'
import clickOutside from '@/views/Planning/directive/clickOutside'
export default defineComponent({
  directives: {
    clickOutside
  },
  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    currentYear: {
      type: Number,
      default: moment().year()
    },
    placeholder: {
      type: String,
      default: '' 
    },
    maxNum: {
      type: Number,
    },
    disabledYear: {
      type: Function as PropType<(date: number) => boolean>
    }
  },
  setup(props, ctx) {
    const yearPickerPanel = ref()
    const yearPickerPanelStyle = reactive({
      left: 'auto',
      right: 'auto',
      top: 'auto',
      bottom: 'auto'
    })

    const showYearPickerPanel = ref(false)

    const startYear = ref(Math.floor(props.currentYear / 10) * 10 - 1)
    const selectedYearValue = ref<number[]>([])

    const openYearPickerPanel = () => {
      if (yearPickerPanel.value) {
        const box = yearPickerPanel.value.getBoundingClientRect()

        if (box.top + 370 > document.body.offsetHeight) {
          yearPickerPanelStyle.top = 'auto'
          yearPickerPanelStyle.bottom = '0'
          yearPickerPanelStyle.left = '0'
        } else {
          yearPickerPanelStyle.top = '0'
          yearPickerPanelStyle.bottom = 'auto'
          yearPickerPanelStyle.left = '0'
        }
      }
      selectedYearValue.value = Object.assign([], props.value)
      startYear.value = Math.floor(props.currentYear / 10) * 10 - 1
      showYearPickerPanel.value = true
    }

    const closeYearPickerPanel = () => {
      showYearPickerPanel.value = false
    }

    const deleteYearValue = (year: number) => {
      const years = props.value.filter((item) => item != year)
      ctx.emit('update:value', years)
    }

    const toPrevYearPickerPanel = () => {
      startYear.value -= 10
    }

    const toNextYearPickerPanel = () => {
      startYear.value += 10
    }

    const yearRangeText = computed(() => {
      return `${startYear.value + 1}-${startYear.value + 10}`
    })

    const yearPanelData = computed(() => {
      const yearData: {year: number; selected: boolean}[][] = []
      let year = startYear.value
      for (let i = 0; i < 4; i++) {
        const rowData = []
        for (let j = 0; j < 3; j++) {
          rowData.push({
            year: year + j,
            selected: selectedYearValue.value.includes(year + j)
          })
        }
        yearData.push(rowData)
        year += 3
      }
      return yearData
    })

    const clickYearData = (yearData: {year: number; selected: boolean}) => {
      if (yearData.year === startYear.value) {
        toPrevYearPickerPanel()
      } else if (yearData.year === startYear.value + 11) {
        toNextYearPickerPanel()
      } else {
        if (yearData.selected) {
          selectedYearValue.value = selectedYearValue.value.filter((item) => item != yearData.year)
        } else {
          if (props.maxNum !== undefined && selectedYearValue.value.length === props.maxNum) {
            return
          }
          if (props.disabledYear && props.disabledYear(yearData.year)) {
            return false
          } 
          const years = selectedYearValue.value
          years.push(yearData.year)
          years.sort()
          selectedYearValue.value = years
        }
      }
    }

    const deleteSelectedYearValue = (year: number) => {
      selectedYearValue.value = selectedYearValue.value.filter((item) => item != year)
    }

    const toSelectYearValue = () => {
      ctx.emit('update:value', Object.assign([], selectedYearValue.value))
      closeYearPickerPanel()
    }

    return {
      yearPickerPanel,
      yearPickerPanelStyle,
      showYearPickerPanel,
      startYear,
      selectedYearValue,
      openYearPickerPanel,
      closeYearPickerPanel,
      deleteYearValue,
      yearRangeText,
      yearPanelData,
      toPrevYearPickerPanel,
      toNextYearPickerPanel,
      clickYearData,
      deleteSelectedYearValue,
      toSelectYearValue
    }
  }
})
