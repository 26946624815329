
import { defineComponent, ref, computed, h, resolveComponent, watch, reactive, toRef } from 'vue'
import type { Ref } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import { AdjReleaseViewType, AdjReleaseInputViewType, AdjReleaseInputType, AdjReleaseType } from '@/views/Planning/type'
import type { Month, ReleaseData, TopicData, AdjAfterClosingData, AdjustmentDataInfo, AdjustmentData, AdjustmentDetailDataParam } from '@/views/Planning/type'
import Decimal from '@/utils/closing/decimal'
import { elementSize } from '@/utils'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import useCheckTreeData from '@/hooks/planning/useCheckTreeData'
import type { CheckState } from '@/hooks/planning/useCheckTreeData'
import EditableView from '@/views/Planning/components/EditableView.vue'
import useTableTreeDataKey from '@/hooks/planning/useTableTreeDataKey'
import useTableTreeDataLevel from '@/hooks/planning/useTableTreeDataLevel'
import useExpandTableTreeData from '@/hooks/planning/useExpandTableTreeData'
import useTreeDataRelation from '@/hooks/planning/useTreeDataRelation'
import deepClone from '@/utils/deepClone'
import { monthes, monthesEn, monthNumberToEn, monthEnToNumber, forEachRecursive, numberSubWithPrecision } from '@/utils/planning'
import {
  getArTopicList as getArTopicListAPI,
  getArBrandList as getArBrandListAPI,
  reportByTopicAndAdjustment as reportByTopicAndAdjustmentAPI,
  getAfterClosingReport as getAfterClosingReportAPI,
  getAdjLabelList as getAdjLabelListAPI,
  getAdjustmentInfo as getAdjustmentInfoAPI,
  addAdjustmnetLabel as addAdjustmnetLabelAPI,
  newAdjustmentData as newAdjustmentDataAPI,
  saveAdjustmentData as saveAdjustmentDataAPI,
  exportAjustment as exportAjustmentAPI
} from '@/API/planning'
import moment from 'moment'
import { message } from 'ant-design-vue'

const rowKey = 'key'

interface AdjustmentTotalStatData {
  sdPercent?: string;
  sd?: string;
}

interface Adjustments {
  id?: number;
  planningTypeId?: number;
  adjustmentNo: string;
  name?: string;
  topic?: string;
  selectedBrands: string[];
  inputType: AdjReleaseInputType;
  inputViewType: AdjReleaseInputViewType;
  viewType: AdjReleaseViewType;
  amount: string;
  avgSI: string;
  expandModel?: boolean;
  inited: boolean;
  data?: AdjustmentData[];
  monthCheckStatus: Record<Month, boolean>;
  expandedRowKeys?: Ref<(number|string)[]>;
  handleExpand?: (expand: boolean, v: AdjustmentData) => void;
  parent?: (c: AdjustmentData) => AdjustmentData | null;
  children?: (c: AdjustmentData) => AdjustmentData[] | null;
  checkState?: (c: AdjustmentData) => CheckState | undefined;
  change?: (c: AdjustmentData) => void;
}

export default defineComponent({
  components: {
    TagSelector,
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
    emits: [
    'save-data'
  ],
  setup(props, context) {
    const statData = reactive<AdjustmentTotalStatData>({
      sdPercent: '0.00',
      sd: '0'
    })
    const adjustments = ref<Adjustments[]>([])
    const activeKey = ref(-1)
    // topic列表
    const topics = ref<TopicData[]>([])
    // 品牌列表
    const brands = ref<string[]>([])
    // After Closing选中品牌
    const afterClosingSelectedBrands = ref<string[]>([])

    const afterClosingStatTableColumns = ref<unknown[]>([])
    const afterClosingStatData = ref<Record<string, string>[]>([])

    const topicStatTableColumns = ref<unknown[]>([])
    const topicStatData = ref<Record<string, string>[]>([])

    const viewTypes = [AdjReleaseViewType.WsWolume, AdjReleaseViewType.WsLLP, AdjReleaseViewType.AvgSI, AdjReleaseViewType.Amount]
    const inputViewTypes = [AdjReleaseInputViewType.AvgSI, AdjReleaseInputViewType.Amount]

    const afterClosingViewType = ref<AdjReleaseViewType>(AdjReleaseViewType.WsWolume)

    const actualStatus = computed(() => {
      const actualStatusMap = reactive(new Map(monthes.map((item: string) => [item, false])))
      if (props.kufriVersion) {
        const kufriMonth = props.kufriVersion.replace('K', '')
        if (kufriMonth) {
          for (let i = 0; i < 12; i++) {
            if (`${props.year}${monthes[i]}` < kufriMonth) {
              actualStatusMap.set(monthes[i], true)
            }
          }
        }
      }
      return actualStatusMap
    })

    const dataTableColumnRender = (index: number, column: string) => {
      return ({ record }: {record: AdjustmentData}) => {
        if (adjustments.value[index].inputType === AdjReleaseInputType.Input &&
          column !== 'annual' &&
          (([2, 3].includes((record as AdjustmentData & {level: number}).level) &&
              adjustments.value[index].viewType === AdjReleaseViewType.AvgSI &&
              adjustments.value[index].inputViewType === AdjReleaseInputViewType.AvgSI) ||
            ((record as AdjustmentData & {level: number}).level === 3) && 
              adjustments.value[index].viewType === AdjReleaseViewType.Amount &&
              adjustments.value[index].inputViewType === AdjReleaseInputViewType.Amount) &&
          !actualStatus.value.get(monthEnToNumber(column) as string)) {
          const props: Record<string, unknown> = {
            class: 'editable-cell',
            disabled: !record[column].editable,
            onClickOutside: () => {
              if (adjustments.value[index].viewType == AdjReleaseViewType.AvgSI) {
                if (record[column].finalPoint !== record[column].originFinalPoint) {
                  if ((record as AdjustmentData & {level: number}).level === 3) { // 修改impactModel层级后typeClass层级不能修改
                    record[column].wsAmount = new Decimal(record[column].wsLlp).times(record[column].finalPoint).div(100).round(15, false)
                    const parentFunc = adjustments.value[index].parent
                    const parentRecord = parentFunc ? parentFunc(record) : null
                    if (parentRecord && parentRecord[column]) {
                      parentRecord[column].editable = false
                    }
                  } else { // 修改typeClass层级后impactModel层级不能修改
                    record[column].wsAmount = new Decimal(record[column].wsLlp).times(record[column].finalPoint).div(100).round(15, false)
                    const childrenFunc = adjustments.value[index].children
                    const childrenRecords = childrenFunc ? childrenFunc(record) : null
                    childrenRecords?.forEach((item) => {
                      item[column].finalPoint = record[column].finalPoint
                      item[column].wsAmount = new Decimal(item[column].wsLlp).times(item[column].finalPoint).div(100).round(15, false)
                      item[column].editable = false
                    })
                  }
                }
              } else {
                if (record[column].wsAmount !== record[column].originWsAmount) {
                  if ((record as AdjustmentData & {level: number}).level === 3) { // 修改impactModel层级后typeClass层级不能修改
                    record[column].finalPoint = record[column].wsLlp ? new Decimal(record[column].wsAmount).div(record[column].wsLlp).times(100).round(15, false) : '0'
                    const parentFunc = adjustments.value[index].parent
                    const parentRecord = parentFunc ? parentFunc(record) : null
                    if (parentRecord && parentRecord[column]) {
                      parentRecord[column].editable = false
                    }
                  } else { // 修改typeClass层级后impactModel层级不能修改
                    record[column].finalPoint = record[column].wsLlp ? new Decimal(record[column].wsAmount).div(record[column].wsLlp).times(100).round(15, false) : '0'
                    const childrenFunc = adjustments.value[index].children
                    const childrenRecords = childrenFunc ? childrenFunc(record) : null
                    childrenRecords?.forEach((item) => {
                      item[column].finalPoint = record[column].finalPoint
                      item[column].wsAmount = new Decimal(item[column].wsLlp).times(item[column].finalPoint).div(100).round(15, false)
                      item[column].editable = false
                    })
                  }
                }
              }
            },
            value: adjustments.value[index].viewType == AdjReleaseViewType.AvgSI ? record[column].finalPoint : record[column].wsAmount,
            'onUpdate:value': (value: string) => {
              if (adjustments.value[index].viewType == AdjReleaseViewType.AvgSI) {
                record[column].finalPoint = numberSubWithPrecision(value, 15)
              } else {
                record[column].wsAmount = numberSubWithPrecision(value, 15)
              }
            },
            formator: (value: string) => {
              if (adjustments.value[index].viewType == AdjReleaseViewType.AvgSI) {
                return value === '' ? '' : `${new Decimal(value).round(2)}%`
              } else {
                return value === '' ? '' : new Decimal(value).round(0)
              }
            } 
          }
          return h(EditableView, props)
        }

        let text = ''
        if (record[column]) {
          switch (adjustments.value[index].viewType) {
            case AdjReleaseViewType.WsWolume:
              text = record[column].wsVolume !== null ? new Decimal(record[column].wsVolume).round(0) : ''
              break
            case AdjReleaseViewType.WsLLP:
              text = record[column].wsLlp !== null ? new Decimal(record[column].wsLlp).round(0) : ''
              break
            case AdjReleaseViewType.AvgSI:
              text = record[column].finalPoint !== '' ? `${new Decimal(record[column].finalPoint ?? '').round(2)}%` : ''
              break
            case AdjReleaseViewType.Amount:
              text = record[column].wsAmount !== '' ? new Decimal(record[column].wsAmount ?? '').round(0) : ''
              break
          }
        }
        return h('span', text)
      }
    }

    const dataTableCheckboxColumnRender = (index: number) => {
      return ({ record }: {record: AdjustmentData}) => {
        const checkStateFunc = adjustments.value?.[index]?.checkState
        const checkState = checkStateFunc ? checkStateFunc(record) : undefined
        const checkboxComponent = resolveComponent('a-checkbox')
        return h(checkboxComponent, checkState ? {
          checked: checkState.checked,
          'onUpdate:checked': (value: boolean) => {
            checkState.checked = value
          },
          indeterminate: checkState.indeterminate,
          onChange: (checked: boolean) => {
            const changeFunc = adjustments.value?.[index]?.change
            if (changeFunc) {
              changeFunc(record)
            }
          }
        }: null)
      }
    }

    const afterClosingTableData = ref<AdjAfterClosingData[]>([])
    const afterClosingExpandModel = ref(false)

    // After Closing表格展开
    const {expandedRowKeys: afterClosingExpandedRowKeys, handleExpand: handleAfterClosingExpand} = useExpandTableTreeData(afterClosingTableData, afterClosingExpandModel)

    // After Closing月份Actual/Plan状态
    const afterClosingActualStatus = computed(() => {
      const actualStatusMap = reactive(new Map(monthes.map((item: string) => [item, false])))
      if (props.kufriVersion) {
        const kufriMonth = props.kufriVersion.replace('K', '')
        if (kufriMonth) {
          for (let i = 0; i < 12; i++) {
            if (`${props.year}${monthes[i]}` < kufriMonth) {
              actualStatusMap.set(monthes[i], true)
            }
          }
        }
      }
      return actualStatusMap
    })

    
    const afterClosingDataTableColumnRender = (column: string) => {
      return ({ record }: {record: ReleaseData}) => {
        let text = ''
        if (record[column]) {
          switch (afterClosingViewType.value) {
            case AdjReleaseViewType.WsWolume:
              text = record[column].wsVolume !== null ? new Decimal(record[column].wsVolume).round(0) : ''
              break
            case AdjReleaseViewType.WsLLP:
              text = record[column].wsLlp !== null ? new Decimal(record[column].wsLlp).round(0) : ''
              break
            case AdjReleaseViewType.AvgSI:
              text = record[column].finalPoint !== null ? `${new Decimal(record[column].finalPoint ?? '').round(2)}%` : ''
              break
            case AdjReleaseViewType.Amount:
              text = record[column].wsAmount !== null ? new Decimal(record[column].wsAmount ?? '').round(0) : ''
              break
          }
        }
        return h('span', text)
      }
    }

    const afterClosingTableColumns = [
      {
        slots: { title: 'vehicle' },
        dataIndex: 'vehicle',
        width: 350,
        fixed: true,
      },
      {
        dataIndex: 'brand',
        width: 60,
        fixed: true,
      },
      {
        slots: { title: 'annual' },
        dataIndex: 'annual',
        align: 'right',
        width: 130,
        fixed: true,
        customRender: afterClosingDataTableColumnRender('annual')
      },
      ...monthes.map((month, index) => {
        return {
          slots: { title: month },
          dataIndex: month,
          align: 'right',
          width: index === monthes.length - 1 ? 130 : 110,
          customRender: afterClosingDataTableColumnRender(monthNumberToEn(month) as string)
        }
      })
    ]

    const tableColumn = computed(() => {
      return (index: number) => {
        const columns = [
          {
            slots: { title: 'vehicle' },
            dataIndex: 'vehicle',
            width: 350,
            fixed: 'left'
          },
          {
            dataIndex: 'brand',
            width: 60,
            fixed: 'left'
          },
          {
            slots: { title: 'annual' },
            dataIndex: 'annual',
            align: 'right',
            width: 130,
            fixed: 'left',
            customRender: dataTableColumnRender(index, 'annual')
          },
          ...monthes.map((month, i) => {
            return {
              slots: { title: month },
              dataIndex: month,
              align: 'right',
              width: i === monthes.length ? 130 : 110,
              customRender: dataTableColumnRender(index, monthNumberToEn(month) as string)
            }
          })
        ]

        if (adjustments.value[index].inputType === AdjReleaseInputType.Allocation) {
          columns.unshift({
            slots: { title: 'rowCheckbox' },
            dataIndex: 'annual',
            align: 'center',
            width: 50,
            fixed: 'left',
            customRender: dataTableCheckboxColumnRender(index)
          })
        }

        return columns
      }
    })
    
    const statTableColumnRender = ({ text, index }: { text: string; index: number}) => {
      return h('span', index === 0 ? `${new Decimal(text).round(2)}%` : new Decimal(text).round(0))
    }

    const tableSize = elementSize('.release-table')

    const tableHeaderSize = elementSize('.release-table .ant-table-thead')

    const handleAddAdjustment = async () =>  {
      const data = await addAdjustmnetLabelAPI(props.planningId as number, props.kufriVersion as string, adjustments.value.map(item => item.adjustmentNo))
      adjustments.value.push({
        planningTypeId: data[0].planningTypeId,
        adjustmentNo: data[0].adjustmentNo,
        name: '',
        selectedBrands: deepClone(brands.value),
        inputType: AdjReleaseInputType.Allocation,
        inputViewType: AdjReleaseInputViewType.Amount,
        viewType: AdjReleaseViewType.WsLLP,
        amount: '',
        avgSI: '',
        expandModel: false,
        data: data[0].children,
        monthCheckStatus: monthes.reduce((status, month) => {
          status[month] = false
          return status
        }, {}) as Record<Month, boolean>,
        inited: true
      })

      const adjustmentIndex = adjustments.value.length - 1
      forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].finalPoint === null ? '' : item[month].finalPoint.toString()
            item[month].originFinalPoint = item[month].finalPoint
            item[month].wsAmount = item[month].wsAmount === null ? '' : item[month].wsAmount.toString()
            item[month].originWsAmount = item[month].wsAmount
            item[month].editable = true
          } else {
            item[month] = {
              finalPoint: '',
              originFinalPoint: '',
              wsAmount: '',
              originWsAmount: '',
              wsLlp: null,
              wsVolume: null,
              editable: true
            }
          }
        })
      })
      const dataRef = toRef(adjustments.value[adjustmentIndex], 'data') as Ref<AdjustmentData[]>
      useTableTreeDataKey(dataRef)
      useTableTreeDataLevel(dataRef)
      const expandModelRef = toRef(adjustments.value[adjustmentIndex], 'expandModel') as Ref<boolean>
      const { expandedRowKeys, handleExpand } = useExpandTableTreeData(dataRef, expandModelRef)
      adjustments.value[adjustmentIndex].expandedRowKeys = expandedRowKeys as any
      adjustments.value[adjustmentIndex].handleExpand = handleExpand
      const { parent, children } = useTreeDataRelation(dataRef)
      adjustments.value[adjustmentIndex].parent = parent
      adjustments.value[adjustmentIndex].children = children
      const { checkState, change } = useCheckTreeData(dataRef, parent, children)
      adjustments.value[adjustmentIndex].checkState = checkState
      adjustments.value[adjustmentIndex].change = change
      
      activeKey.value = adjustments.value.length - 1
    }

    const getAfterClosingReport = async () => {
      if (!props.planningId) {
        return
      }
      if (afterClosingSelectedBrands.value.length) {
        afterClosingTableData.value = (await getAfterClosingReportAPI(props.planningId, afterClosingSelectedBrands.value))[0].children
        useTableTreeDataKey(afterClosingTableData)
      } else {
        afterClosingTableData.value = []
      }
    }
    
    const reportByTopicAndAdjustment = async () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }
      const data = await reportByTopicAndAdjustmentAPI(props.planningId, props.kufriVersion)
      afterClosingStatTableColumns.value = []
      topicStatTableColumns.value = []
      const afterClosingStatColumns: string[] = []
      const topicStatColumns: string[] = []
      data.colInfoList?.forEach(col => {
        switch (col.colType) {
          case 'afterClosing':
            afterClosingStatColumns.push(col.colName)
            afterClosingStatTableColumns.value.push({
              title: col.colName,
              dataIndex: col.colName,
              width: 150,
              align: 'right',
              customRender: statTableColumnRender
            })
            break;
          case 'topic':
            topicStatColumns.push(col.colName)
            topicStatTableColumns.value.push({
              title: col.colName,
              dataIndex: col.colName,
              // width: 100,
              align: 'right',
              customRender: statTableColumnRender
            })
            break;
        }
      })
      afterClosingStatData.value = []
      topicStatData.value = []
      data.rowList.forEach((row, index) => {
        afterClosingStatData.value[index] = {}
        topicStatData.value[index] = {}
        row.colList.forEach(data => {
          if (data.colName === props.year) {
            if (index === 0) {
              statData.sdPercent = `${new Decimal(data.colValue).round(2)}%`
            } else {
              statData.sd = new Decimal(data.colValue).round(0)
            }
          }
          else if (afterClosingStatColumns.includes(data.colName)) {
            if (index === 0) {
              afterClosingStatData.value[index][data.colName] = data.colValue
            } else {
              afterClosingStatData.value[index][data.colName] = data.colValue
            }
          } else {
            if (index === 0) {
              topicStatData.value[index][data.colName] = data.colValue
            } else {
              topicStatData.value[index][data.colName] = data.colValue
            }
          }
        })
      })
    }

    const init = () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }
      activeKey.value = -1
      getArTopicListAPI(props.planningId as number).then(data => {
        topics.value = data
      })
      getArBrandListAPI(props.planningId, props.kufriVersion, AdjReleaseType.Adjustment).then(data => {
        brands.value = data
        afterClosingSelectedBrands.value = deepClone(data)
      }).then(() => {
        getAfterClosingReport()
        getAdjLabelListAPI(props.planningId as number, props.kufriVersion as string).then(data => {
          adjustments.value = data ? data.map(item => {
            return {
              id: item.id,
              adjustmentNo: item.adjustmentNo,
              name: '',
              selectedBrands: deepClone(brands.value),
              inputType: AdjReleaseInputType.Allocation,
              inputViewType: AdjReleaseInputViewType.Amount,
              viewType: AdjReleaseViewType.WsLLP,
              amount: '',
              avgSI: '',
              expandModel: false,
              monthCheckStatus: monthes.reduce((status, month) => {
                status[month] = false
                return status
              }, {}) as Record<Month, boolean>,
              inited: false
            }
          }) : []
        })
      })
      reportByTopicAndAdjustment()
    }

    const getArInfo = async (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].selectedBrands.length) {
        const data = await getAdjustmentInfoAPI(adjustments.value[adjustmentIndex].selectedBrands, adjustments.value[adjustmentIndex].id as number)
        adjustments.value[adjustmentIndex].name = data.topicName
        adjustments.value[adjustmentIndex].topic = data.topicCode ? data.topicCode.toString() : undefined
        adjustments.value[adjustmentIndex].data = data.children
        forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
          monthesEn.forEach(month => {
            if (item[month]) {
              item[month].finalPoint = item[month].finalPoint === null ? '' : item[month].finalPoint.toString()
              item[month].originFinalPoint = item[month].finalPoint
              item[month].wsAmount = item[month].wsAmount === null ? '' : item[month].wsAmount.toString()
              item[month].originWsAmount = item[month].wsAmount
              item[month].editable = true
            } else {
              item[month] = {
                finalPoint: '',
                originFinalPoint: '',
                wsAmount: '',
                originWsAmount: '',
                wsLlp: null,
                wsVolume: null,
                editable: true
              }
            }
          })
        })
        const dataRef = toRef(adjustments.value[adjustmentIndex], 'data') as Ref<AdjustmentData[]>
        useTableTreeDataKey(dataRef)
      } else {
        adjustments.value[adjustmentIndex].data = []
      }
    }

    const getNewArInfo = async (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].selectedBrands.length) {
        const data = await newAdjustmentDataAPI(adjustments.value[adjustmentIndex].selectedBrands, props.planningId as number,
          props.kufriVersion as string, adjustments.value[adjustmentIndex].adjustmentNo)
        adjustments.value[adjustmentIndex].data = data[0].children
        forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
          monthesEn.forEach(month => {
            if (item[month]) {
              item[month].finalPoint = item[month].finalPoint === null ? '' : item[month].finalPoint.toString()
              item[month].originFinalPoint = item[month].finalPoint
              item[month].wsAmount = item[month].wsAmount === null ? '' : item[month].wsAmount.toString()
              item[month].originWsAmount = item[month].wsAmount
              item[month].editable = true
            } else {
              item[month] = {
                finalPoint: '',
                originFinalPoint: '',
                wsAmount: '',
                originWsAmount: '',
                wsLlp: null,
                wsVolume: null,
                editable: true
              }
            }
          })
        })
        const dataRef = toRef(adjustments.value[adjustmentIndex], 'data') as Ref<AdjustmentData[]>
        useTableTreeDataKey(dataRef)
      } else {
        adjustments.value[adjustmentIndex].data = []
      }
    }

    const handleChangeBrand = (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].id) {
        getArInfo(adjustmentIndex)
      } else {
        getNewArInfo(adjustmentIndex)
      }
    }

    const handleChangeInputType = (adjustmentIndex: number) => {
      adjustments.value[adjustmentIndex].amount = ''
      adjustments.value[adjustmentIndex].avgSI = ''
      forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].originFinalPoint
            item[month].wsAmount = item[month].originWsAmount
          }
        })
      })
      if (adjustments.value[adjustmentIndex].inputType === AdjReleaseInputType.Allocation) {
        adjustments.value[adjustmentIndex].viewType = AdjReleaseViewType.WsLLP
      } else {
        adjustments.value[adjustmentIndex].viewType = AdjReleaseViewType.Amount
        adjustments.value[adjustmentIndex].inputViewType = AdjReleaseInputViewType.Amount
      }
    }

    const handleChangeViewType = (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].viewType === AdjReleaseViewType.AvgSI) {
        adjustments.value[adjustmentIndex].inputViewType = AdjReleaseInputViewType.AvgSI
      } else if (adjustments.value[adjustmentIndex].viewType === AdjReleaseViewType.Amount) {
        adjustments.value[adjustmentIndex].inputViewType = AdjReleaseInputViewType.Amount
      }
    }

    const handleChangeInputViewType = (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].inputViewType === AdjReleaseInputViewType.AvgSI) {
        adjustments.value[adjustmentIndex].viewType = AdjReleaseViewType.AvgSI
      } else if (adjustments.value[adjustmentIndex].inputViewType === AdjReleaseInputViewType.Amount) {
        adjustments.value[adjustmentIndex].viewType = AdjReleaseViewType.Amount
      }
    }

    const handleAllocation = (adjustmentIndex: number) => {
      if (adjustments.value[adjustmentIndex].amount === '') {
        message.error('Please input amount')
        return
      }
      let llp = new Decimal(0)
      const checkedCells: AdjustmentDataInfo[] = []
      forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
        const checkStateFunc = adjustments.value[adjustmentIndex]?.checkState
        const checkState = checkStateFunc ? checkStateFunc(item)?.checked : false
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].originFinalPoint
            item[month].wsAmount = item[month].originWsAmount
          }
          if (checkState && adjustments.value[adjustmentIndex].monthCheckStatus[monthEnToNumber(month) as Month]) {
            if ((item as AdjustmentData & {level: number}).level === 3) {
              llp = llp.plus(item[month]?.wsLlp)
            }
            checkedCells.push(item[month])
          }
        })
      })
      const avgSI = llp.isZero() ? new Decimal(0) : new Decimal(adjustments.value[adjustmentIndex].amount as string).div(llp)
      adjustments.value[adjustmentIndex].avgSI = `${avgSI.times(100).round(2)}%`
      checkedCells.forEach(item => {
        item.finalPoint = avgSI.times(100).round(15, false)
        item.wsAmount = new Decimal(item.wsLlp).times(avgSI).round(15, false)
      })
    }

    const handleChangeAmount = (adjustmentIndex: number) => {
      adjustments.value[adjustmentIndex].amount = numberSubWithPrecision(adjustments.value[adjustmentIndex].amount, 2, 13, true)
    }

    const handleSave = async (adjustmentIndex: number) => {
      if (!adjustments.value[adjustmentIndex].name) {
        message.error('Please input name')
        return
      }
      if (!adjustments.value[adjustmentIndex].topic) {
        message.error('Please select topic')
        return
      }
      if (adjustments.value[adjustmentIndex].id) {
        const AdjustmentDataInfos: {
          id: number;
          planningId?: number;
          planningTypeId?: number;
          kufriVersion?: string;
          year?: string;
          month?: string;
          brand?: string;
          finalPoint: number;
          typeClass?: string;
          impactCode?: string;
          impactName?: string;
          actualStatus?: boolean;
          topicName?: string;
          topicCode?: number;
          adjustmentNo?: string;
          wsAmount?: number | null;
        }[] = []
        forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
          if ((item as AdjustmentData & {level: number}).level === 3) {
            monthesEn.forEach(month => {
              if (item[month] && item[month].finalPoint !== item[month].originFinalPoint) {
                AdjustmentDataInfos.push({
                  id: item[month].dataInfoId,
                  planningId: props.planningId,
                  planningTypeId: adjustments.value[adjustmentIndex].planningTypeId,
                  kufriVersion: props.kufriVersion,
                  year: props.year,
                  month: monthEnToNumber(month, true),
                  brand: item.brand,
                  typeClass: item.typeClass,
                  impactCode: item.impactCode,
                  impactName: item.vehicle,
                  actualStatus: actualStatus.value.get(monthEnToNumber(month) as string),
                  topicName: adjustments.value[adjustmentIndex].name,
                  topicCode: parseInt(adjustments.value[adjustmentIndex].topic as string),
                  adjustmentNo: adjustments.value[adjustmentIndex].adjustmentNo,
                  finalPoint: parseFloat(item[month].finalPoint),
                  wsAmount: item[month].wsAmount === '' ? null : parseFloat(item[month].wsAmount)
                })
              }
            })
          }
        })
        await saveAdjustmentDataAPI({
          allocationAmount: adjustments.value[adjustmentIndex].inputType === AdjReleaseInputType.Allocation ?
            parseFloat(adjustments.value[adjustmentIndex].amount) : undefined,
          allocationType: adjustments.value[adjustmentIndex].inputType === AdjReleaseInputType.Input ? 0 : 1,
          detailList: AdjustmentDataInfos,
          id: adjustments.value[adjustmentIndex].id,
          topicCode: parseInt(adjustments.value[adjustmentIndex].topic as string),
          topicName: adjustments.value[adjustmentIndex].name
        })
      } else {
        const AdjustmentDataInfos: AdjustmentDetailDataParam[] = []
        forEachRecursive(adjustments.value[adjustmentIndex].data as AdjustmentData[], item => {
          if ((item as AdjustmentData & {level: number}).level === 3) {
            monthesEn.forEach(month => {
              if (item[month]) {
                AdjustmentDataInfos.push({
                  planningId: props.planningId as number,
                  planningTypeId: adjustments.value[adjustmentIndex].planningTypeId as number,
                  actualStatus: actualStatus.value.get(monthEnToNumber(month) as string) as boolean,
                  adjustmentNo: adjustments.value[adjustmentIndex].adjustmentNo,
                  brand: item.brand,
                  finalPoint: item[month].finalPoint === '' ? null : parseFloat(item[month].finalPoint),
                  impactCode: item.impactCode as string,
                  impactName: item.vehicle,
                  kufriVersion: props.kufriVersion as string,
                  month: monthEnToNumber(month, true) as string,
                  msrp: item[month].msrp,
                  rtLlp: item[month].rtLlp,
                  rtVolume: item[month].rtVolume,
                  topicCode: parseInt(adjustments.value[adjustmentIndex].topic as string),
                  topicName: adjustments.value[adjustmentIndex].name as string,
                  typeClass: item.typeClass as string, 
                  wsLlp: item[month].wsLlp,
                  wsVolume: item[month].wsVolume,
                  year: props.year as string,
                  wsAmount: item[month].wsAmount === '' ? null : parseFloat(item[month].wsAmount)
                })
              }
            })
          }
        })
        const id = await saveAdjustmentDataAPI({
          adjustmentNo: adjustments.value[adjustmentIndex].adjustmentNo,
          allocationAmount: adjustments.value[adjustmentIndex].inputType === AdjReleaseInputType.Allocation ?
            parseFloat(adjustments.value[adjustmentIndex].amount) : undefined,
          allocationType: adjustments.value[adjustmentIndex].inputType === AdjReleaseInputType.Input ? 0 : 1,
          detailList: AdjustmentDataInfos,
          kufriVersion: props.kufriVersion,
          planningId: props.planningId,
          planningTypeId: adjustments.value[adjustmentIndex].planningTypeId,
          topicCode: parseInt(adjustments.value[adjustmentIndex].topic as string),
          topicName: adjustments.value[adjustmentIndex].name,
          year: props.year
        })
        adjustments.value[adjustmentIndex].id = id
      }
      message.success('Save successful')
      context.emit('save-data')
      getArInfo(adjustmentIndex)
      reportByTopicAndAdjustment()
    }

    const headerId = ref<number>(-1)

    watch(activeKey, async (adjustmentIndex: number) => {
      if (adjustmentIndex === -1) {
        return
      }
      headerId.value = adjustmentIndex
      if (adjustments.value[adjustmentIndex].inited === false) {
        if (adjustments.value[adjustmentIndex].id) {
          await getArInfo(adjustmentIndex)
        }
        adjustments.value[adjustmentIndex].inited = true
        const dataRef = toRef(adjustments.value[adjustmentIndex], 'data') as Ref<AdjustmentData[]>
        useTableTreeDataLevel(dataRef)
        const expandModelRef = toRef(adjustments.value[adjustmentIndex], 'expandModel') as Ref<boolean>
        const { expandedRowKeys, handleExpand } = useExpandTableTreeData(dataRef, expandModelRef)
        adjustments.value[adjustmentIndex].expandedRowKeys = expandedRowKeys as any
        adjustments.value[adjustmentIndex].handleExpand = handleExpand
        const { parent, children } = useTreeDataRelation(dataRef)
        adjustments.value[adjustmentIndex].parent = parent
        adjustments.value[adjustmentIndex].children = children
        const { checkState, change } = useCheckTreeData(dataRef, parent, children)
        adjustments.value[adjustmentIndex].checkState = checkState
        adjustments.value[adjustmentIndex].change = change
      }
    }, {
      immediate: true
    })

    watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
      if (kufriVersionValue && planningIdValue) {
        init()
      }
    }, {
      immediate: true
    })

    const handleExport = async () => {
      const postData = {
        brandList: afterClosingSelectedBrands.value,
        headerId: adjustments.value[headerId.value].id
      }
      const res = await exportAjustmentAPI(postData)
      downloadFromStream(res)
    }

    return {
      statData,
      brands,
      afterClosingSelectedBrands,
      afterClosingStatTableColumns,
      topicStatTableColumns,
      afterClosingStatData,
      topicStatData,
      activeKey,
      rowKey,
      moment,
      afterClosingTableColumns,
      afterClosingTableData,
      afterClosingExpandModel,
      afterClosingExpandedRowKeys,
      handleAfterClosingExpand,
      afterClosingActualStatus,
      actualStatus,
      adjustments,
      topics,
      viewTypes,
      inputViewTypes,
      afterClosingViewType,
      AdjReleaseInputType,
      monthes,
      tableColumn,
      tableSize,
      tableHeaderSize,
      handleAddAdjustment,
      getAfterClosingReport,
      handleChangeAmount,
      handleChangeBrand,
      handleChangeInputType,
      handleChangeViewType,
      handleChangeInputViewType,
      handleAllocation,
      handleSave,
      handleExport
    }
  }
})
