
import { defineComponent, ref, reactive, h, inject, watch } from 'vue'
import type { Ref } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import Decimal from '@/utils/closing/decimal'
import moment from 'moment'
import type { ColumnProps } from 'ant-design-vue/es/table/interface';
import { PlanningSimulationTab, FcInputType } from '@/views/Planning/type'
import type { FcInputTypeData } from '@/views/Planning/type'
import CentralRegion from '@/views/Planning/PlanningSimulation/CentralRegion.vue'
import Fleet from '@/views/Planning/PlanningSimulation/Fleet.vue'
import Adjustment from '@/views/Planning/PlanningSimulation/Adjustment.vue'
import Release from '@/views/Planning/PlanningSimulation/Release.vue'
import DealerStock from '@/views/Planning/PlanningSimulation/DealerStock.vue'
import { makeKey, scenarioIdKey, kufriKey, scenarioKey, planningIdKey, typePlanningOrSimulationKey, createMonthKey, currentKufriKey, simulationYearKey } from '@/views/Planning/index.vue'
import {
  getPlanningYear as getPlanningYearAPI,
  getTotalData as getTotalDataAPI,
  getFcInputTypeList as getFcInputTypeListAPI
} from '@/API/planning'

interface PlanningYear {
  year: string;
  id: number;
  createMonth: string;
  currentKufri: string;
  kufriMonth: string;
}

interface TotalStatData {
  ws?: string;
  rt?: string;
  sdPercent?: string;
  sd?: string;
}

interface TypeStatData {
  cr: number | string;
  fleetUC: number | string;
  fp: number | string;
  bidding: number | string;
  dsAdj: number | string;
  specialAdj: number | string;
  release: number | string;
}

function generateKufirVersions(begin: string, end: string) {
  const versions = []
  const month = moment(begin.replace('K', ''))
  const endMonth = moment(end.replace('K', ''))
  while (!month.isAfter(endMonth)) {
    versions.push(month.format('YYYYKMM'))
    month.add(1, 'M')
  }

  return versions
}

export default defineComponent({
  components: {
    TagSelector,
    CentralRegion,
    Fleet,
    DealerStock,
    Adjustment,
    Release
  },
  setup() {
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const scenario = inject<Ref<string>>(scenarioKey) as Ref<string>
    const scenarioId = inject<Ref<number>>(scenarioIdKey) as Ref<number>
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    const defaultPlanningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const typePlanningOrSimulation = inject<Ref<string>>(typePlanningOrSimulationKey) as Ref<string>
    const createMonth = inject<Ref<string>>(createMonthKey) as Ref<string>
    const currentKufri = inject<Ref<string>>(currentKufriKey) as Ref<string>
    const simulationYear = inject<Ref<string>>(simulationYearKey) as Ref<string>

    const years = ref<string[]>()
    const year = ref<string>()
    let yearPlanningIdMap = new Map<string, number>()
    const kufris = ref<string[]>()
    const kufri = ref<string>()
    const planningId = ref<number>()

    const fcInputTypes = reactive<{[key in FcInputType]?: number}>({})

    const activeKey = ref(PlanningSimulationTab.GentralRegion)

    const totalStatData = reactive<TotalStatData>({
      ws: '0',
      rt: '0',
      sdPercent: '0.00',
      sd: '0'
    })

    const typeStatColumnActive = (column: string) => {
      if (column === 'fleetUC' && [PlanningSimulationTab.Fleet, PlanningSimulationTab.UC].includes(activeKey.value)) {
        return true
      }
      return column === activeKey.value
    }

    const typeStatTableColumnRender = (column: string) => {
      return ({ text, index }: {text: string; index: number}) => {
        const className = typeStatColumnActive(column) ? 'active' : ''
        const formatedNumber = index === 0 ? `${new Decimal(text).round(2)}%` : new Decimal(text).round(0)
        return h('span', { class: className }, formatedNumber)
      }
    }

    const typeStatTabletColumns = [{
        titleName: 'C+R',
        dataIndex: 'cr'
      },
      {
        titleName: 'Fleet&UC',
        dataIndex: 'fleetUC'
      },      {
        titleName: 'FP',
        dataIndex: 'fp'
      },      {
        titleName: 'Bidding',
        dataIndex: 'bidding'
      },      {
        titleName: 'DS Adj.',
        dataIndex: 'dsAdj'
      },      {
        titleName: 'Special Adj.',
        dataIndex: 'specialAdj'
      },      {
        titleName: 'Release',
        dataIndex: 'release'
      }
    ].map((item: ColumnProps, index, arr) => {
      if (item.dataIndex) {
        item.slots = { title: item.dataIndex }
        item.customRender = typeStatTableColumnRender(item.dataIndex)
      }
      item.align = 'right'
      item.width = index === arr.length - 1 ? 120 : 100
      return item
    })

    const typeStatData = ref<TypeStatData[]>([])

    const getStatData = async () => {
      if (!planningId.value || !kufri.value) {
        return
      }
      const data = await getTotalDataAPI(planningId.value, kufri.value)
      totalStatData.ws = new Decimal(data.totalWsVolume ?? '0').round(0)
      totalStatData.rt = new Decimal(data.totalRtVolume ?? '0').round(0)
      
      typeStatData.value = [
        {
          cr: data.totalCr?.totalAvgSi ?? '0',
          fleetUC: data.totalFc?.totalAvgSi ?? '0',
          fp: data.totalFp?.totalAvgSi ?? '0',
          bidding: data.totalBidding?.totalAvgSi ?? '0',
          dsAdj: data.totalDlr?.totalAvgSi ?? '0',
          specialAdj: data.totalSpec?.totalAvgSi ?? '0',
          release: data.totalRel?.totalAvgSi ?? '0'
        },
        {
          cr: data.totalCr?.totalAmount ?? '0',
          fleetUC: data.totalFc?.totalAmount ?? '0',
          fp: data.totalFp?.totalAmount ?? '0',
          bidding: data.totalBidding?.totalAmount ?? '0',
          dsAdj: data.totalDlr?.totalAmount ?? '0',
          specialAdj: data.totalSpec?.totalAmount ?? '0',
          release: data.totalRel?.totalAmount ?? '0'
        }
      ]

      totalStatData.sdPercent = new Decimal(0).plus(data.totalCr?.totalAvgSi ?? 0)
        .plus(data.totalFc?.totalAvgSi ?? 0)
        .plus(data.totalFp?.totalAvgSi ?? 0)
        .plus(data.totalBidding?.totalAvgSi ?? 0)
        .plus(data.totalDlr?.totalAvgSi ?? 0)
        .plus(data.totalSpec?.totalAvgSi ?? 0)
        .plus(data.totalRel?.totalAvgSi ?? 0)
        .round(2)

      totalStatData.sd = new Decimal(0).plus(data.totalCr?.totalAmount ?? 0)
        .plus(data.totalFc?.totalAmount ?? 0)
        .plus(data.totalFp?.totalAmount ?? 0)
        .plus(data.totalBidding?.totalAmount ?? 0)
        .plus(data.totalDlr?.totalAmount ?? 0)
        .plus(data.totalSpec?.totalAmount ?? 0)
        .plus(data.totalRel?.totalAmount ?? 0)
        .round(0)
    }

    watch([year, kufri] as const, ([yearValue]) => {
      if (typePlanningOrSimulation.value === 'simulation') {
        planningId.value = defaultPlanningId.value
      } else {
        planningId.value = yearValue ? yearPlanningIdMap.get(yearValue): undefined
      }
      getStatData()
    })

    const getPlanningYearInfo = () => {
      getPlanningYearAPI({
        make: make.value,
        scenarioId: scenarioId.value,
        kufriMonth: kufriMonth.value
      }).then((data: {data: PlanningYear[]}) => {
        year.value = undefined
        yearPlanningIdMap = new Map()
        if (data.data.length) {
          data.data.forEach((item: PlanningYear) => {
            if (item.year) {
              yearPlanningIdMap.set(item.year, item.id)
              if (item.id === defaultPlanningId.value) {
                year.value = item.year
              }
            }
          })

          years.value = Array.from(yearPlanningIdMap.keys()).sort()
          if (!year.value && years.value.length) {
            year.value = years.value[0]
          }
          const kufriVersionStart = data.data[0].createMonth
          const KufriVersionEnd = data.data[0].currentKufri
          kufris.value = generateKufirVersions(kufriVersionStart, KufriVersionEnd)
          kufri.value = kufris.value[kufris.value.length - 1]
        }
      })
    }

    const getFcInputType = () => {
      getFcInputTypeListAPI().then((data: FcInputTypeData[]) => {
        data.forEach(item => {
          fcInputTypes[item.typeName] = item.typeId
        })
      })
    }

    const handleSearch = () => {
      if (typePlanningOrSimulation.value === 'simulation') {
        getStatData()
      } else {
        getPlanningYearInfo()
      }
      
    }

    const getKufriAndYear = () => {
      planningId.value = defaultPlanningId.value
      const kufriVersionStart = createMonth.value
      const KufriVersionEnd = currentKufri.value
      kufris.value = generateKufirVersions(kufriVersionStart, KufriVersionEnd)
      kufri.value = kufris.value[kufris.value.length - 1]
      years.value = [simulationYear.value]
      year.value = simulationYear.value
    }

    if (typePlanningOrSimulation.value === 'simulation') {
      getKufriAndYear()
    } else {
      getPlanningYearInfo()
    }
    getFcInputType()

    return {
      scenario,
      years,
      year,
      kufris,
      kufri,
      make,
      planningId,
      fcInputTypes,
      FcInputType,
      getStatData,
      handleSearch,
      totalStatData,
      typeStatTabletColumns,
      typeStatData,
      activeKey,
      PlanningSimulationTab,
      typeStatColumnActive
    }
  }
})
