import { watch } from 'vue'
import type { Ref } from 'vue'

type Data = Partial<Record<string, Data[]|unknown>>

export default function<T extends Data>(data: Ref<T[]>, childrenKey = 'children', key = 'level') {
  const setLevel = (data: T[], level = 1) => {
    data.forEach((item: T) => {
      (item[key] as number) = level
      if (item[childrenKey] && (item[childrenKey] as T[]).length) {
        setLevel(item[childrenKey] as T[], level + 1)
      }
    })
  }

  watch(data, () => {
    setLevel(data.value)
  }, {
    immediate: true
  })
}