import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d2479ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table_column = _resolveComponent("a-table-column")!
  const _component_a_table_column_group = _resolveComponent("a-table-column-group")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    pagination: false,
    dataSource: _ctx.tableData,
    class: "data-table dlr-table",
    scroll: { y: _ctx.viewHeight - 200, x: 960 },
    rowKey: "id"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table_column_group, {
        title: _ctx.year-1 + '-12',
        align: "right",
        fixed: _ctx.make != 'PBP'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_table_column, {
            title: "Type Class",
            key: "typeClass",
            width: 90,
            dataIndex: "typeClass",
            align: "left"
          }),
          _createVNode(_component_a_table_column, {
            title: "Model",
            key: "model",
            width: 220,
            dataIndex: "model",
            align: "left"
          }),
          _createVNode(_component_a_table_column, {
            title: "Brand",
            key: "brand",
            width: 80,
            dataIndex: "brand",
            align: "left"
          }),
          _createVNode(_component_a_table_column, {
            title: "DS Opening Balance",
            key: "openingBalance",
            width: 150,
            dataIndex: "openingBalance",
            align: "right"
          })
        ]),
        _: 1
      }, 8, ["title", "fixed"]),
      _createVNode(_component_a_table_column, {
        width: 20,
        class: "bg-fff"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.seasons, (season, index) => {
        return (_openBlock(), _createBlock(_component_a_table_column_group, {
          title: season.title,
          align: "center",
          key: season.title
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_a_table_column, {
              key: season.children[0].dataIndex,
              width: season.children[0].width,
              dataIndex: season.children[0].dataIndex,
              align: "right",
              class: "bg-f8f8f8"
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(season.children[0].title), 1),
                    (season.children[0].canExpand)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                          (season.children[0].expandStatus)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                onClick: ($event: any) => (_ctx.handleExpandCol(season.children[0], season)),
                                class: "expand-icon"
                              }, "-", 8, _hoisted_3))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                onClick: ($event: any) => (_ctx.handleExpandCol(season.children[0], season)),
                                class: "expand-icon"
                              }, "+", 8, _hoisted_4))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["width", "dataIndex"])),
            (season.children[1].isShow)
              ? (_openBlock(), _createBlock(_component_a_table_column, {
                  title: season.children[1].title,
                  key: season.children[1].dataIndex,
                  width: season.children[1].width,
                  dataIndex: season.children[1].dataIndex,
                  align: "right",
                  class: "bg-f8f8f8"
                }, null, 8, ["title", "width", "dataIndex"]))
              : _createCommentVNode("", true),
            (season.children[2].isShow)
              ? (_openBlock(), _createBlock(_component_a_table_column, {
                  title: season.children[2].title,
                  key: season.children[2].dataIndex,
                  width: season.children[2].width,
                  dataIndex: season.children[2].dataIndex,
                  align: "right",
                  class: "input-cell"
                }, {
                  default: _withCtx(({ record }) => [
                    (!record[season.field].editing)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "cell",
                          onClick: ($event: any) => (_ctx.handleClickCell(record, season.field))
                        }, _toDisplayString(_ctx.number2Fixed(record[season.field].adjAvgSi)), 9, _hoisted_5))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          (season.field === 'march')
                            ? (_openBlock(), _createBlock(_component_a_input, {
                                key: 0,
                                class: _normalizeClass(["input-adj", {'bg-E0EAEE': !record.model}]),
                                type: "number",
                                value: record[season.field].adjAvgSi,
                                "onUpdate:value": ($event: any) => ((record[season.field].adjAvgSi) = $event),
                                disabled: !record.model || _ctx.marchDisabled,
                                onBlur: ($event: any) => (_ctx.handleBlur(record, season.field)),
                                onFocus: ($event: any) => (_ctx.handleFocus(record, season.field))
                              }, null, 8, ["class", "value", "onUpdate:value", "disabled", "onBlur", "onFocus"]))
                            : (season.field === 'june')
                              ? (_openBlock(), _createBlock(_component_a_input, {
                                  key: 1,
                                  class: _normalizeClass(["input-adj", {'bg-E0EAEE': !record.model}]),
                                  type: "number",
                                  value: record[season.field].adjAvgSi,
                                  "onUpdate:value": ($event: any) => ((record[season.field].adjAvgSi) = $event),
                                  disabled: !record.model || _ctx.juneDisabled,
                                  onBlur: ($event: any) => (_ctx.handleBlur(record, season.field)),
                                  onFocus: ($event: any) => (_ctx.handleFocus(record, season.field))
                                }, null, 8, ["class", "value", "onUpdate:value", "disabled", "onBlur", "onFocus"]))
                              : (season.field === 'september')
                                ? (_openBlock(), _createBlock(_component_a_input, {
                                    key: 2,
                                    class: _normalizeClass(["input-adj", {'bg-E0EAEE': !record.model}]),
                                    type: "number",
                                    value: record[season.field].adjAvgSi,
                                    "onUpdate:value": ($event: any) => ((record[season.field].adjAvgSi) = $event),
                                    disabled: !record.model || _ctx.septemberDisabled,
                                    onBlur: ($event: any) => (_ctx.handleBlur(record, season.field)),
                                    onFocus: ($event: any) => (_ctx.handleFocus(record, season.field))
                                  }, null, 8, ["class", "value", "onUpdate:value", "disabled", "onBlur", "onFocus"]))
                                : (_openBlock(), _createBlock(_component_a_input, {
                                    key: 3,
                                    class: _normalizeClass(["input-adj", {'bg-E0EAEE': !record.model}]),
                                    type: "number",
                                    value: record[season.field].adjAvgSi,
                                    "onUpdate:value": ($event: any) => ((record[season.field].adjAvgSi) = $event),
                                    disabled: !record.model || _ctx.decemberDisabled,
                                    onBlur: ($event: any) => (_ctx.handleBlur(record, season.field)),
                                    onFocus: ($event: any) => (_ctx.handleFocus(record, season.field))
                                  }, null, 8, ["class", "value", "onUpdate:value", "disabled", "onBlur", "onFocus"]))
                        ]))
                  ]),
                  _: 2
                }, 1032, ["title", "width", "dataIndex"]))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_component_a_table_column, {
              title: season.children[3].title,
              key: season.children[3].dataIndex,
              width: season.children[3].width,
              dataIndex: season.children[3].dataIndex,
              align: "right",
              class: "bg-f8f8f8"
            }, null, 8, ["title", "width", "dataIndex"])),
            (index != 3)
              ? (_openBlock(), _createBlock(_component_a_table_column, {
                  key: 2,
                  class: "bg-fff",
                  width: 20,
                  style: {"background":"#FFF !important"}
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["dataSource", "scroll"]))
}