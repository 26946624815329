
import moment from 'moment'
import type { Moment } from 'moment'
import { defineComponent, onMounted, ref, inject  } from 'vue'
import type { Ref } from 'vue'
import { HomePageInfo } from '@/views/Planning/type'
import { getScenrioList, getKufriList, getPlanningYear, removePlanning, lockPlanning, createPlanning, uploadPlanningFm, downloadFmWsTemplate, getQueryDataNumber, exportFmWs, getBaseList, saveSimulation, initProcess } from '@/API/planning'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import MuiltYearPicker from '@/views/Planning/components/MuiltYearPicker.vue'
import Upload from '@/views/Planning/components/Upload.vue'
import { StateStep } from '@/views/Planning/type'
import { makeKey, kufriKey, planningIdKey, scenarioKey, scenarioIdKey, stateStepKey, simulationYearKey, typePlanningOrSimulationKey,createMonthKey, currentKufriKey, planningYearKey } from '@/views/Planning/index.vue'
import { Modal, message } from 'ant-design-vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import isExcel from "@/utils/closing/isExcel"
import { useStore } from 'vuex'
const columns = [
  {
    title: 'Planning Scenario',
    dataIndex: 'planningName',
    key: 'planningName',
    width: 500,
    slots: { customRender: 'name' }
  },
  {
    title: 'Kufri',
    dataIndex: 'kufriMonth',
    key: 'kufriMonth',
    slots: { customRender: 'kufri' },
    width: 90
  },
  {
    title: 'Create Time',
    dataIndex: 'createTime',
    key: 'createTime',
    slots: { customRender: 'create' },
    width: 110
  },
  {
    title: 'Operation',
    key: 'action',
    slots: { customRender: 'action' },
    width: 120
  },
  {
    title: '',
    key: 'simulation1',
    width: 20,
    customHeaderCell: () => {
      return {
        style: {
          border: '0px !important'
        }
      }
    },
    customCell: () => {
      return {
        style: {
          border: '0px !important',
          'backgroundColor': '#FFF !important'
        }
      }
    }
  },
  {
    title: '',
    key: 'simulation1',
    slots: { customRender: 'simulation1' },
    width: 160,
    align: 'left',
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important',
          'backgroundColor': '#FFF !important'
        }
      }
    }
  },
  {
    title: 'Simulation',
    key: 'simulation2',
    slots: { customRender: 'simulation2' },
    width: 160,
    customHeaderCell: () => {
      return {
        style: {
          'text-align': 'center'
        }
      }
    },
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important',
          'backgroundColor': '#FFF !important'
        }
      }
    }
  },
  {
    title: '',
    key: 'simulation3',
    slots: { customRender: 'simulation3' },
    width: 160,
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important',
          'backgroundColor': '#FFF !important'
        }
      }
    }
  },
]
const data = [
  {
    planningName: 'DBP',
    kufriMonth: '2021K08',
    createTime: '2021.08.28'
  }
]
export default defineComponent({
  components: {
    YearPicker,
    MuiltYearPicker,
    Upload
  },
  emits: [
    'simulation'
  ],
  setup(props, ctx) {
    const store = useStore()
    const currentYear = ref<Moment>(moment())
    const scenarioOptions = ref<any[]>([])
    const kufriOptions = ref<any[]>([])
    const showUploadDrawer = ref<boolean>(false)
    
    const currentRow = ref<any>({})
    const uploadMake = ref<string>('')
    const currentUploadMake = ref<string>('')
    const currentUploadYear = ref<string>('')    
    const currentPlanningYear = ref<string>('')
    const currentPlanningId = ref<string>('')
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const kufri = inject<Ref<string>>(kufriKey) as Ref<string>
    const planningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const scenario = inject<Ref<string>>(scenarioKey) as Ref<string>
    const scenarioId = inject<Ref<number>>(scenarioIdKey) as Ref<number>
    const stateStep = inject<Ref<StateStep>>(stateStepKey) as Ref<StateStep>
    const simulationYear = inject<Ref<string>>(simulationYearKey) as Ref<string>
    const typePlanningOrSimulation = inject<Ref<string>>(typePlanningOrSimulationKey) as Ref<string>
    const createMonth = inject<Ref<string>>(createMonthKey) as Ref<string>
    const currentKufri = inject<Ref<string>>(currentKufriKey) as Ref<string>
    const planningYear = inject<Ref<string>>(planningYearKey) as Ref<string>

    const FmYears = ref<any>({
      CBU: [],
      PBP: [],
      VAN: []
    })

    const parentId = ref<any>(null)
    const baseOptions = ref<any []>()
    const modalShow = ref<boolean>(false)
    const simulationDescripe = ref<string>('')

    const initData = async () => {
      const params = {
        activeStatus: true
      }
      const _scenarioResult = await getScenrioList(params)
      scenarioOptions.value = _scenarioResult.data
    }
    initData()
    const allInfo = ref<HomePageInfo[]>([
      {
        name: 'CBU',
        list: [],
        code: 'PC0048',
        createInfo: {
          planningName: undefined,
          kufriMonth: undefined,
          kufriOptions: [],
          createYears: [],
          make: ''
        }
      },
      {
        name: 'PBP',
        list: [],
        code: 'PC0049',
        createInfo: {
          planningName: undefined,
          kufriMonth: undefined,
          kufriOptions: [],
          createYears: [],
          make: ''
        }
      },
      {
        name: 'VAN',
        list: [],
        code: 'PC0050',
        createInfo: {
          planningName: undefined,
          kufriMonth: undefined,
          kufriOptions: [],
          createYears: [],
          make: ''
        }
      },
      // {
      //   name: 'DENZA',
      //   list: [],
      //   code: 'PC0051',
      //   createInfo: {
      //     planningName: undefined,
      //     kufriMonth: undefined,
      //     kufriOptions: [],
      //     createYears: [],
      //     make: ''
      //   }
      // }
    ])
    const tableColumns = ref(columns)
    const tableData = ref(data)
    const getPlanningList = async (type: string) => {
      const _params = {
        make: type,
        currentYear: currentYear.value.format('YYYY')
      }
      const _FmList = await getQueryDataNumber({make: type})
      FmYears.value[type] = _FmList.data?.map((item: any) => item.year)
      const _result = await getPlanningYear(_params)
      _result.data.forEach((item: any) => {
        const stamp = new Date(item.createAt)
        const y = stamp.getFullYear()
        const m = stamp.getMonth() > 8 ? stamp.getMonth() + 1 : `0${stamp.getMonth() + 1}`
        const d = stamp.getDate() > 9 ? stamp.getDate() : '0' + stamp.getDate()
        item.createTime = `${y}.${m}.${d}`
        if (FmYears.value[type].includes(item.year)) {
          item.uploadStatus = true
        } else {
          item.uploadStatus = false
        }
      })
      return _result.data
    }
    const handleCreatePlanning = (type: string) => {
      const nowYear = new Date().getFullYear()
      const _currentYear = parseInt(currentYear.value.format('YYYY'))
      if (_currentYear < nowYear) {
        return message.error('Current year does not allow creation.')
      }

      const _item = allInfo.value.filter((item: any) => {
        return item.name === type
      })
      const _postData: any = {
        scenrioName: _item[0].createInfo.planningName,
        createYears: (_item[0].createInfo.createYears || []).join(','),
        make: type,
        kufriMonth: _item[0].createInfo.kufriMonth,
        currentYear: currentYear.value.format('YYYY')
      }
      if (!_postData.scenrioName) {
        return message.error('Please select Scenrio')
      }
      if (!_postData.kufriMonth) {
        return message.error('Please select Kufri')
      }
      if (!_postData.createYears) {
        return message.error('Please select Planning Year')
      }
      createPlanning(_postData).then(async (res) => {
        const _list = res.basicInfoVOS
        _list.forEach((item: any) => {
          const stamp = new Date(item.createAt)
          const y = stamp.getFullYear()
          const m = stamp.getMonth() + 1
          const d = stamp.getDate()
          item.createTime = `${y}.${m}.${d}`
          if (FmYears.value[type].includes(item.year)) {
            item.uploadStatus = true
          } else {
            item.uploadStatus = false
          }
        })
        if (res.failPlanngingYear?.length > 0) {
          const text = `${res.failPlanngingYear.join(',')} already exits, cannot be created repeatedly`
          message.error(text)
        } else {
          message.success('Create Success')
        }
        const _kufris = await getKufriList({make: type})
        allInfo.value.forEach((item: any) => {
          if (item.name === type) {
            item.list = _list
            item.createInfo = {
              planningName: undefined,
              kufriMonth: undefined,
              createYears: [],
              kufriOptions: _kufris.data
            }
          }
        })
      })
    }

    const methodsGetAllInfo = (flag: boolean) => {
      allInfo.value.forEach(async (item: any) => {
        if (item.name === 'CBU') {
          item.list = await getPlanningList('CBU')
          if (flag) {
            const res = await getKufriList({make: 'CBU'})
            item.createInfo.kufriOptions = res.data
          }
        }
        if (item.name === 'PBP') {
          item.list = await getPlanningList('PBP')
          if (flag) {
            const res = await getKufriList({make: 'PBP'})
            item.createInfo.kufriOptions = res.data
          }
        }
        if (item.name === 'VAN') {
          item.list = await getPlanningList('VAN')
          if (flag) {
            const res = await getKufriList({make: 'VAN'})
            item.createInfo.kufriOptions = res.data
          }
        }
        if (item.name === 'DENZA') {
          item.list = await getPlanningList('DENZA')
          if (flag) {
            const res = await getKufriList({make: 'DENZA'})
            item.createInfo.kufriOptions = res.data
          }
        }
      })
    }

    const farmatCurrentYear = ref<string>(new Date().getFullYear().toString())
    const handleChangeYear = (year: any) => {
      methodsGetAllInfo(false)
      farmatCurrentYear.value = year.format('YYYY')
    }

    const handleLockPlanning = (record: any, id: number, lockStatus: boolean, planningName: string) => {
      const content: string = lockStatus ? `Unlock ${planningName}?` : `Lock ${planningName}?`
      Modal.confirm({
        title: 'Tips',
        content: content,
        okText: 'Confirm',
        onOk() {
          const text: string = lockStatus ? `Confirm Unlock` : `Confirm Lock`
          Modal.confirm({
            title: 'Tips',
            content: text,
            okText: 'Confirm',
            onOk() {
              const _postData = {
                planningId: id,
                lockStatus: !lockStatus
              }
              lockPlanning(_postData).then(() => {
                record.lockStatus = !record.lockStatus
              })
            },
            cancelText: 'Cancel',
          })
        },
        cancelText: 'Cancel',
      })
    }

    const handleClickFile = (record: any) => {
      showUploadDrawer.value = true
      currentPlanningId.value = record.id
      currentUploadMake.value = record.make
      currentUploadYear.value  = record.createYear
      currentPlanningYear.value = record.year
      currentRow.value = record
      uploadMake.value = record.make
      planningYear.value = record.year
    }

    const handleDownloadTemplate = async() => {
        const data = await downloadFmWsTemplate(currentPlanningId.value)
        downloadFromStream(data)
    }

    const handleUpload = async (file: File, resolve: (value: unknown) => void, reject: (reason?: unknown) => void, options: any) => {
      const _formData = new FormData()
      _formData.append('file', file)
      try {
        const fileIsExcel = await isExcel(file)
        if (!fileIsExcel) {
          message.error('file type error')
          throw 'file type error'
        }
        const response = await uploadPlanningFm(_formData, currentUploadMake.value, currentPlanningYear.value, {
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
              const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              options.percent.value = percent
            }
          }
        })
        if (response.data.size === 0) {
          const _FmList = await getQueryDataNumber({make: uploadMake.value})
          const FmYears = _FmList.data?.map((item: any) => item.year)
          allInfo.value.filter((item: any) => item.name === uploadMake.value)[0].list.forEach((row: any) => {
            if (FmYears.includes(row.year)) {
              row.uploadStatus = true
            } else {
              row.uploadStatus = false
            }
          })
          return message.success('Upload Success.')
        }
        if (response.data.type === 'application/json') {
          const result = JSON.parse(await response.data.text())
          if (result.code !== '0') {
            throw result.message
          }
          resolve(null)
        } else {
          downloadFromStream(response)
          throw 'Upload File Error'
        }
      } catch (error) {
        reject(error)
        message.error(error)
      }
      
    }

    const handleExportFm = async (record: any) => {
      if (!record.uploadStatus) {
        return message.error('No Data.')
      }
      // const _params = {
      //   make: record.make,
      //   year: record.createYear
      // }
      const data = await exportFmWs(record.make, record.year)
        downloadFromStream(data)
    }

    const handleRemovePlanning = (planningId: number, make: string, planningName: string) => {
      Modal.confirm({
        title: 'Tips',
        content: `Delete ${planningName} and Clear all related data？`,
        okText: 'Confirm',
        onOk() {
          Modal.confirm({
            title: 'Tips',
            content: 'Confirm Delete',
            okText: 'Confirm',
            onOk() {
              const _postData = planningId
              removePlanning(_postData).then(async () => {
                const _list = await getPlanningList(make)
                allInfo.value.forEach((item: any) => {
                  if (item.name === make) {
                    item.list = _list
                  }
                })
              })
            },
            cancelText: 'Cancel',
          })
        },
        cancelText: 'Cancel',
      })
    }

    let id: number
    let timer: any = null
    const cycle = async () => {
      const res: any = await initProcess({planningId: id})
      store.commit('updateSpinning', true)
      if (res.data) {
        store.commit('updateSpinning', false)
        stateStep.value = StateStep.PlanningSimulation
        ctx.emit('simulation', 'simulation')
        clearInterval(timer)
      }
    }

    const handleToDataPreparation = (record: any, type = 'planning') => {
      id = record.id
      make.value = record.make
      kufri.value = record.kufriMonth
      planningId.value = record.id
      scenario.value = record.planningName
      scenarioId.value = record.scenarioId
      simulationYear.value = record.year
      planningYear.value = record.year
      typePlanningOrSimulation.value = type
      createMonth.value = record.createMonth
      currentKufri.value = record.currentKufri
      if (type === 'simulation') {
        cycle()
        timer = setInterval(cycle, 5000)       
      } else {
        stateStep.value = StateStep.Preparation
      }
    }

    const handleCreateSimulation = async (record: any) => {
      modalShow.value = true
      currentRow.value = record
      const res: any = await getBaseList({planningId: record.id})
      baseOptions.value = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.planningName
        }
      })
    }

    const handleSimulationModalOk = () => {
      if (!parentId.value) {
        return message.error('Please Select Base.')
      }
      if (!simulationDescripe.value) {
        return message.error('Please Input Descripition.')
      }
      const postData = {
        basePlanningId: currentRow.value.id,
        currentYear: currentRow.value.createYear,
        make: currentRow.value.make,
        parentId: parentId.value,
        planningName: simulationDescripe.value,
        scenrioName: currentRow.value.name
      }
      const make = currentRow.value.make
      saveSimulation(postData).then(async (res: any) => {
        message.success('Create Success.')
        modalShow.value = false
        const list = await getPlanningList(make)
        for (let i = 0; i < allInfo.value.length;i ++) {
          if (allInfo.value[i].name === make) {
            allInfo.value[i].list = list
          }
        }
      })
    }

    const handleSimulationModalCancle = () => {
      parentId.value = null
      simulationDescripe.value = ''
    }

    onMounted(() => {
      methodsGetAllInfo(true)
    })

    const disabledYear = (year: number) => {
      return year < moment().year()
    }

    return {
      currentYear,
      farmatCurrentYear,
      scenarioOptions,
      kufriOptions,
      allInfo,
      tableColumns,
      tableData,
      showUploadDrawer,
      currentUploadMake,
      currentUploadYear,    
      currentPlanningYear,
      currentPlanningId,

      baseOptions,
      modalShow,
      parentId,
      simulationDescripe,

      handleChangeYear,
      handleCreatePlanning,
      handleLockPlanning,
      handleClickFile,
      handleDownloadTemplate,
      handleUpload,
      handleRemovePlanning,
      handleExportFm,
      handleToDataPreparation,
      disabledYear,

      handleCreateSimulation,
      handleSimulationModalOk,
      handleSimulationModalCancle
    }
  }
})
