
import { defineComponent, ref, reactive, h, inject, watch, computed, Ref, nextTick } from 'vue'
import { elementSize } from '@/utils'
import { makeKey, kufriKey, planningIdKey } from '@/views/Planning/index.vue'

export default defineComponent({
  props: {
    tableData: {
      type: Array
    },
    year: {
      type: String,
      default: '2021'
    },
    kufriVersion: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    const defaultPlanningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    
    const handleExpandCol = (child: any, season: any) => {
      child.expandStatus = !child.expandStatus
      season.children[1].isShow = !season.children[1].isShow
      season.children[2].isShow = !season.children[2].isShow
    }

    let seasons

    if (make.value === 'PBP') {
      seasons = ref<any []>([
        {
          title: props.year + '-12',
          field: 'december',
          slots: { 
            title: `titleQ4`
          },
          children: [
            {
              title: 'DS Target',
              dataIndex: `december.dsTarget`,
              key: `december.dsTarget`,
              expandStatus: false,
              canExpand: true,
              isShow: true,
              width: 80
            },
            {
              title: 'Avg.SI%',
              dataIndex: `december.avgSi`,
              key: `december.avgSi`,
              isShow: false,
              width: 80,
            },
            {
              title: 'Adj. Avg.SI%',
              dataIndex: `december.adjAvgSi`,
              key: `december.adjAvgSi`,
              isShow: false,
              width: 100
            },
            {
              title: 'Movement',
              dataIndex: `december.movement`,
              isShow: true,
              key: `december.movement`,
              width: 120,
            }
          ]
        }
      ])
    } else {
      seasons = ref<any []>([
        {
          title: props.year + '-03',
          field: 'march',
          slot: 'q1',
          children: [
            {
              title: 'DS Target',
              dataIndex: `march.dsTarget`,
              key: `march.dsTarget`,
              width: 80,
              expandStatus: false,
              canExpand: true,
              isShow: true
            },
            {
              title: 'Avg.SI%',
              dataIndex: `march.avgSi`,
              key: `march.avgSi`,
              width: 80,
              isShow: false
            },
            {
              title: 'Adj. Avg.SI%',
              dataIndex: `march.adjAvgSi`,
              key: `march.adjAvgSi`,
              width: 100,
              isShow: false
            },
            {
              title: 'Movement',
              dataIndex: `march.movement`,
              key: `march.movement`,
              width: 120,
              isShow: true
            }
          ]
        },
        {
          title: props.year + '-06',
          field: 'june',
          slots: { 
            title: `titleQ2`
          },
          children: [
            {
              title: 'DS Target',
              dataIndex: `june.dsTarget`,
              key: `june.dsTarget`,
              expandStatus: false,
              canExpand: true,
              isShow: true,
              width: 80
            },
            {
              title: 'Avg.SI%',
              dataIndex: `june.avgSi`,
              key: `june.avgSi`,
              isShow: false,
              width: 80,
            },
            {
              title: 'Adj. Avg.SI%',
              dataIndex: `june.adjAvgSi`,
              key: `june.adjAvgSi`,
              isShow: false,
              width: 100
            },
            {
              title: 'Movement',
              dataIndex: `june.movement`,
              isShow: true,
              key: `june.movement`,
              width: 120,
            }
          ]
        },
        {
          title: props.year + '-09',
          field: 'september',
          slots: { 
            title: `titleQ3`
          },
          children: [
            {
              title: 'DS Target',
              dataIndex: `september.dsTarget`,
              key: `september.dsTarget`,
              expandStatus: false,
              canExpand: true,
              isShow: true,
              width: 80
            },
            {
              title: 'Avg.SI%',
              dataIndex: `september.avgSi`,
              key: `september.avgSi`,
              isShow: false,
              width: 80,
            },
            {
              title: 'Adj. Avg.SI%',
              dataIndex: `september.adjAvgSi`,
              key: `september.adjAvgSi`,
              isShow: false,
              width: 100
            },
            {
              title: 'Movement',
              dataIndex: `september.movement`,
              isShow: true,
              key: `september.movement`,
              width: 120,
            }
          ]
        },
        {
          title: props.year + '-12',
          field: 'december',
          slots: { 
            title: `titleQ4`
          },
          children: [
            {
              title: 'DS Target',
              dataIndex: `december.dsTarget`,
              key: `december.dsTarget`,
              expandStatus: false,
              canExpand: true,
              isShow: true,
              width: 80
            },
            {
              title: 'Avg.SI%',
              dataIndex: `december.avgSi`,
              key: `december.avgSi`,
              isShow: false,
              width: 80,
            },
            {
              title: 'Adj. Avg.SI%',
              dataIndex: `december.adjAvgSi`,
              key: `december.adjAvgSi`,
              isShow: false,
              width: 100
            },
            {
              title: 'Movement',
              dataIndex: `december.movement`,
              isShow: true,
              key: `december.movement`,
              width: 120,
            }
          ]
        }
      ])
    }

    const computedFilterExpand = computed(() => {
      return (children: any) => {
        return children.filter((child: any) => {
          return child.isShow
        })
      }
    })

    let marchDisabled = false
    let juneDisabled = false 
    let septemberDisabled = false 
    let decemberDisabled = false

    const methodFormat = (value: any) => {
      if (value === 0) {
        return 0
      } else {
        return Number(value.replaceAll(',', ''))
      }
    }

    const handleSaveAvgSi = () => {
      const request: any[] = []
      const months = ['march', 'june', 'september', 'december']
      props.tableData?.forEach((row: any) => {
        console.log(row)
        for (const key in row) {
          if (months.includes(key)) {
            let item: any = {}
            if (row[key].id) {
              item.adjAvgSi = row[key].adjAvgSi !== '' && row[key].adjAvgSi !== null ? parseFloat(row[key].adjAvgSi) : null
              item.id = row[key].id
            } else {
              item = {
                planningId: defaultPlanningId.value,
                kufriVersion: props.kufriVersion,
                brand: row.brand,
                typeClass: row.typeClass,
                year: props.year,
                impactName: row.model,
                month: key === 'march' ? 3 : key === 'june' ? 6 : key === 'september' ? 9 : 12,
                impactCode: row.impactCode,
                adjAvgSi: row[key].adjAvgSi !== '' && row[key].adjAvgSi !== null ? parseFloat(row[key].adjAvgSi) : null,
                adjustmentStatus: false,
                actualStatus: key === 'march' ? marchDisabled : key === 'june' ? juneDisabled : key === 'september' ? septemberDisabled : decemberDisabled,
                openingBalance: methodFormat(row.openingBalance ?? 0),
                allocationAmount: methodFormat(row.stockAdjustment ?? 0)
              }
            }
            console.log(item.adjAvgSi)
            console.log(row[key].originAdjAvgSi)
            if (item.adjAvgSi !== row[key].originAdjAvgSi) {
              request.push(item)
            }            
          }
        }
      })
      return request
    }

    const handleBlur = (record: any, field: string) => {
      record[field].editing = false
    }

    const handleFocus = (record: any, field: string) => {
      // if (!record[field].adjAvgSi) {
      //   return
      // }
      // record[field].adjAvgSi = Number(record[field].adjAvgSi)
    }

    const viewHeight = document.documentElement.clientHeight

    const month = parseInt(props.kufriVersion.substring(props.kufriVersion.length - 2))
    
    const kufriYear = parseInt(props.kufriVersion)

    if (kufriYear > parseInt(props.year)) {
      marchDisabled = true
      juneDisabled = true
      septemberDisabled = true
      decemberDisabled = true
    } else if (kufriYear == parseInt(props.year)) {
      if (month <= 3) {
        marchDisabled = false
        juneDisabled = false
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 3 && month <= 6) {
        marchDisabled = true
        juneDisabled = false
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 6 && month <= 9) {
        marchDisabled = true
        juneDisabled = true
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 9) {
        marchDisabled = true
        juneDisabled = true
        septemberDisabled = true
        decemberDisabled = false
      }
    } else {
      marchDisabled = false
      juneDisabled = false
      septemberDisabled = false
      decemberDisabled = false
    }

    const handleClickCell = (record: any, month: string) => {
      if (month === 'march' && marchDisabled) {
        return
      } else if (month === 'june' && juneDisabled) {
        return
      } else if (month === 'september' && septemberDisabled) {
        return
      } else if (month === 'december' && decemberDisabled) {
        return
      }
      record[month].editing = true
      nextTick(()=>{
        const ele: any | null = document.querySelector('.data-table input')
        if (ele) {
          ele.focus()
        }
      })
    }

    const number2Fixed = computed(() => {
      return (val: any) => {
        if (val || val == 0) {
          // val = val.replaceAll(',', '')
          return Number(val).toFixed(2) + '%'
        }
        return ''
      }
    })
    
    
    return {
      computedFilterExpand,
      handleExpandCol,
      seasons,
      viewHeight,
      handleSaveAvgSi,
      handleBlur, 
      handleClickCell,
      handleFocus,
      make,
      marchDisabled,
      juneDisabled,
      septemberDisabled,
      decemberDisabled,
      number2Fixed
    }
  }
})
