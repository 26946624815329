
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import Calculation from './CurrentYear/Calculation.vue'
import Allocation from './CurrentYear/Allocation.vue'
import { defineComponent, ref, reactive, h, inject, watch, Ref } from 'vue'
import { message } from 'ant-design-vue'
import { getCurrentDealerStock, saveAvgSi, saveAmount, nextYearData, currentExport } from '@/API/planning'
import { makeKey } from '@/views/Planning/index.vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'
export default defineComponent({
  components: {
    TagSelector,
    Calculation,
    Allocation
  },
  props: {
    tableData: {
      type: Array
    },
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
      default: ''
    },
    planningId: {
      type: String,
    },
  },
  setup(props, ctx) {
    const make = inject<Ref<string>>(makeKey) as Ref<string>

    const typeOptions = ref<string []>(['Dealer Stock Adjustment Calculation', 'Dealer Stock Adjustment Allocation'])
    const type = ref<string>('Dealer Stock Adjustment Calculation')

    const calculationRef = ref<any>(null)
    const allocationRef = ref<any>(null)

    const planningList = ref<any []>([])
    const kufriList = ref<any []>([])
    const yearList = ref<any []>([])

    const planningName = ref<string>('')
    const nextYear = ref<string>('')
    const finalKufri = ref<string>('')

    const handleClear = () => {
      allocationRef.value.handleClear()
    }

    const handleSave = () => {
      if (type.value === 'Dealer Stock Adjustment Calculation') {
        const data = calculationRef.value.handleSaveAvgSi()
        saveAvgSi(data).then(res => {
          message.success('Save Success')
          ctx.emit('handle-update-data')
        })
      } else {
        const data = allocationRef.value.handleSave()
        const monthWsLlps = data.detailList.map((item: any) => item.monthWsLlp)
        if (monthWsLlps.some((item: number) => item === 0)) {
          return message.error('Select WS LLP equal to zero.')
        } else {
          for (let i = 0; i < data.detailList.length; i++) {
            delete data.detailList[i].monthWsLlp
          }
        }
        saveAmount(data).then(res => {
          message.success('Save Success')
          ctx.emit('handle-update-data')
        })
      }
    }

    const handleExport = async () => {
      const postData = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }
      const res = await currentExport(postData)
      downloadFromStream(res)
    }

    const nextYearInfo = ref<any[]>([])

    const handleSearch = () => {
      if (!planningName.value) {
        return message.error('Please Select Planning Name')
      }
      if (!nextYear.value) {
        return message.error('Please Select Year')
      }
      if (!finalKufri.value) {
        return message.error('Please Select Final Kufri')
      }
      const targetId = nextYearInfo.value.filter((item: any) => item.planningName === planningName.value && item.year === nextYear.value && item.kufriMonth === finalKufri.value)[0].id
      console.log(targetId)
      ctx.emit('handle-search-by-target-id', targetId)
    }

    const handleChangePlanningName = (value: string) => {
      // kufriList.value = [...new Set(nextYearInfo.value.filter((item: any) => item.planningName === value).map((item: any) => item.kufriMonth))]
      yearList.value = [...new Set(nextYearInfo.value.filter((item: any) => item.planningName === value).map((item: any) => item.year))]
    }

    const handleChangeNextYear = (value: string) => {
      kufriList.value = [...new Set(nextYearInfo.value.filter((item: any) => item.planningName === planningName.value && item.year === value).map((item: any) => item.kufriMonth))]
    }
    
    const init = () => {
      nextYearData({id: props.planningId}).then((res: any) => {       
        if (res.data) {
          nextYearInfo.value = res.data
          planningList.value = [...new Set(res.data.filter((item: any) => item.planningName).map((item: any) => item.planningName))]
          // kufriList.value = [...new Set(res.data.filter((item: any) => item.kufriMonth).map((item: any) => item.kufriMonth))]
          // yearList.value = [...new Set(res.data.filter((item: any) => item.year).map((item: any) => item.year))]
        }
      })
    }

    init()

    return {
      make,
      typeOptions,
      type,
      calculationRef,
      allocationRef,
      handleSave,
      handleClear,
      planningName,
      nextYear,
      finalKufri,
      planningList,
      kufriList,
      yearList,
      handleChangePlanningName,
      handleChangeNextYear,
      handleSearch,
      handleExport
    }
  }
})
