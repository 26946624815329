import { toDisplayString as _toDisplayString, vShow as _vShow, toHandlerKey as _toHandlerKey, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-view", {disabled: _ctx.disabled}])
  }, [
    _withDirectives(_createElementVNode("span", {
      [_toHandlerKey(_ctx.event)]: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, _toDisplayString(_ctx.text), 17), [
      [_vShow, !_ctx.inEditting]
    ]),
    _withDirectives(_createVNode(_component_a_input, _mergeProps({
      ref: "inputComponent",
      onKeyup: _withKeys(_ctx.handleClickOutSide, ["enter"]),
      value: _ctx.valueProp,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueProp) = $event))
    }, _ctx.attrsValue), null, 16, ["onKeyup", "value"]), [
      [_vShow, _ctx.inEditting]
    ])
  ], 2)), [
    [_directive_click_outside, _ctx.handleClickOutSide]
  ])
}