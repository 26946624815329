import type { Ref } from 'vue'
import { ref, watch } from 'vue'

type Data = Partial<Record<string, Data[]|unknown>>

export default function<T extends Data>(data: Ref<T[]>, expand: Ref<boolean>, childrenKey = 'children', key = 'key', view = '') {
  const expandedRowKeys = ref<(number|string)[]>([])

  const getAllExpandRowKeys = () => {
    const expandAllRowKeys = []
    const queue = [...data.value]
    while (queue.length) {
      const item = queue.shift()
      if (item && (item[childrenKey] as T[])?.length) {
        if (item[key]) {
          expandAllRowKeys.push(item[key])
        }
        queue.push(...item[childrenKey] as T[])
      }
    }
    return expandAllRowKeys
  }

  
  const handleExpand = (expanded: boolean, record: T) => {
    if (expanded) {
      expandedRowKeys.value.push(record[key] as string|number)
    } else {
      const index = expandedRowKeys.value.indexOf(record[key] as string|number)
      if (index !== -1) {
        expandedRowKeys.value.splice(index, 1)
      }
    }
  }

  watch([data, expand], ([_dataValue, expandValue]) => {
    if (expandValue) {
      expandedRowKeys.value = getAllExpandRowKeys() as (string|number)[]
    } else {
      expandedRowKeys.value = data.value.length ? [data.value[0][key] as string|number] : []
      if (view === 'finalView') {
        expandedRowKeys.value = []
      }
    }
  }, {
    immediate: true
  })

  return {
    expandedRowKeys,
    handleExpand
  }
}