import type { Ref } from 'vue'
import { watch, ref } from 'vue'

type Data = Partial<Record<string, Data[]|unknown>>

export interface CheckState {
  checked: boolean;
  indeterminate: boolean;
} 

function initCheckStateData<T extends Data>(data: T[], childrenKey = 'children', checkStates: Map<T, CheckState>) {
  data.forEach(item => {
    checkStates.set(item, {
      checked: false,
      indeterminate: false
    })
    if ((item[childrenKey] as T[])?.length) {
      initCheckStateData(item[childrenKey] as T[], childrenKey, checkStates)
    }
  })
  return checkStates
}

export default function<T extends Data>(data: Ref<T[]>, parent: (c: T) => T | null, children: (c: T) => T[] | null, childrenKey = 'children') {
  const checkStates = ref(new Map<T, CheckState>())

  watch(data, (dataValue) => {
    checkStates.value = initCheckStateData(dataValue, childrenKey, new Map<T, CheckState>())
  }, {
    immediate: true
  })

  const checkChildren = (c: T, checked: boolean) => {
    children(c)?.forEach(item => {
      const checkState = checkStates.value.get(item)
      if (checkState) {
        checkState.checked = checked
      }
      checkChildren(item, checked)
    })
  }

  const checkParent = (c: T, checked: boolean) => {
    const parentData: T | null = parent(c)
    if (!parentData) {
      return
    }
    const parentCheckState = checkStates.value.get(parentData)
    if (!parentCheckState) {
      return
    }
    parentCheckState.indeterminate = true
    if (parentData) {
      let checkedChildrenNum = 0
      const childrenData = children(parentData)
      if (childrenData) {
        childrenData?.forEach(item => {
          const checkState = checkStates.value.get(item)
          if (checkState && checkState.checked) {
            checkedChildrenNum++
          }
        })
        parentCheckState.indeterminate = checkedChildrenNum > 0 && checkedChildrenNum < childrenData.length

        parentCheckState.checked = checkedChildrenNum === childrenData.length
      }
      checkParent(parentData, checked)
    }
  }

  return {
    checkState(c: T) {
      return checkStates.value.get(c)
    },
    change(c: T) {
      const checkState = checkStates.value.get(c)
      if (!checkState) {
        return
      }
      checkChildren(c, checkState.checked)
      checkParent(c, checkState.checked)
    }
  }
}