
import { defineComponent, ref, computed, h, resolveComponent, watch, reactive, onMounted, nextTick, Ref, inject } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import { AdjReleaseType, DealerStockViewType, AdjReleaseInputType } from '@/views/Planning/type'
import type { Month, TopicData, AdjustmentData, AdjustmentDataInfo } from '@/views/Planning/type'
import Decimal from '@/utils/closing/decimal'
import { elementSize } from '@/utils'
import EditableView from '@/views/Planning/components/EditableView.vue' 
import deepClone from '@/utils/deepClone'
import useTableTreeDataKey from '@/hooks/planning/useTableTreeDataKey'
import useTableTreeDataLevel from '@/hooks/planning/useTableTreeDataLevel'
import useExpandTableTreeData from '@/hooks/planning/useExpandTableTreeData'
import useTreeDataRelation from '@/hooks/planning/useTreeDataRelation'
import useCheckTreeData from '@/hooks/planning/useCheckTreeData'
import { monthes, monthesEn, monthNumberToEn, monthEnToNumber, forEachRecursive, numberSubWithPrecision } from '@/utils/planning'
import moment from 'moment'
import { message } from 'ant-design-vue'
import {
  getArBrandList as getArBrandListAPI,
  getReleaseInfo as getReleaseInfoAPI,
  saveAdjustmentData as saveAdjustmentDataAPI,
  getfutureDealerStock, saveAvgSi, featureExport
} from '@/API/planning'
import { kufriKey, planningIdKey } from '@/views/Planning/index.vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'

const rowKey = 'key'

export default defineComponent({
  components: {
    TagSelector,
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  setup(props) {
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    // const defaultPlanningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const monthesInput = ref<any []>([
      {
        label: 'january',
        value: null,
        editing: false,
        number: 1
      },
      {
        label: 'february',
        value: null,
        editing: false,
        number: 2
      },
      {
        label: 'march',
        value: null,
        editing: false,
        number: 3
      },
      {
        label: 'april',
        value: null,
        editing: false,
        number: 4
      },
      {
        label: 'may',
        value: null,
        editing: false,
        number: 5
      },
      {
        label: 'june',
        value: null,
        editing: false,
        number: 6
      },
      {
        label: 'july',
        value: null,
        editing: false,
        number: 7
      },
      {
        label: 'august',
        value: null,
        editing: false,
        number: 8
      },
      {
        label: 'september',
        value: null,
        editing: false,
        number: 9
      },
      {
        label: 'october',
        value: null,
        editing: false,
        number: 10
      },
      {
        label: 'november',
        value: null,
        editing: false,
        number: 11
      },
      {
        label: 'december',
        value: null,
        editing: false,
        number: 12
      }
    ])
    let id: number | undefined = undefined

    const viewTypes = ref<DealerStockViewType[]>([DealerStockViewType.WsWolume, DealerStockViewType.WsLLP, DealerStockViewType.AvgSI, DealerStockViewType.Amount])
    const viewType = ref<DealerStockViewType>(DealerStockViewType.WsLLP)  
    const inputType = ref<AdjReleaseInputType>(AdjReleaseInputType.Allocation)

    const amount = ref<string>('')
    const avgSI = ref<string>('')

    const selectedRows = ref<any[]>([])

    const expandModel = ref(false)

    // 品牌列表
    const brands = ref<string[]>([])
    const selectedBrands = ref<string[]>([])

    const tableData = ref<AdjustmentData[]>([])
    let copyTableData: any[] = []

    const actualStatus = computed(() => {
      const actualStatusMap = reactive(new Map(monthes.map((item: string) => [item, false])))
      tableData.value?.forEach((make: AdjustmentData) => {
        make.children?.forEach((typeClass: AdjustmentData) => {
          typeClass.children?.forEach((model: AdjustmentData) => {
            monthesEn.forEach((monthEn: string) => {
              if (model[monthEn]?.actualStatus !== undefined) {
                const monthNumber = monthEnToNumber(monthEn)
                if (monthNumber) {
                  actualStatusMap.set(monthNumber, model[monthEn]?.actualStatus)
                }
              }
            })
          })
        })
      })
      return actualStatusMap
    })

    useTableTreeDataLevel(tableData)
    const { expandedRowKeys, handleExpand } = useExpandTableTreeData(tableData, expandModel)
    const { parent, children } = useTreeDataRelation(tableData)
    const { checkState, change } = useCheckTreeData(tableData, parent, children)

    const dataTableColumnRender = (column: string) => {
      return ({ record }: {record: AdjustmentData}) => {
        if (inputType.value === AdjReleaseInputType.Input &&
          column !== 'annual' &&
          (([2, 3].includes((record as AdjustmentData & {level: number}).level) &&
              viewType.value === DealerStockViewType.AvgSI) ||
            ((record as AdjustmentData & {level: number}).level === 3 &&
              viewType.value === DealerStockViewType.Amount))) {
            
          const props: Record<string, unknown> = {
            disabled: true,
            value: viewType.value == DealerStockViewType.AvgSI ? record[column].finalPoint : record[column].wsAmount,
            'onUpdate:value': (value: string) => {
              if (viewType.value == DealerStockViewType.AvgSI) {
                record[column].finalPoint = value
              } else {
                record[column].wsAmount = value
              }
            },
            formator: (value: string) => {
              if (viewType.value == DealerStockViewType.AvgSI) {
                return `${new Decimal(value).round(2)}%`
              } else {
                return new Decimal(value).round(2)
              }
            } 
          }
          return h(EditableView, props)
        }
        let text = ''
        if (record[column]) {
          switch (viewType.value) {
            case DealerStockViewType.WsWolume:
              text = record[column].wsVolume !== null ? new Decimal(record[column].wsVolume).round(0) : ''
              break
            case DealerStockViewType.WsLLP:
              text = record[column].wsLlp !== null ? new Decimal(record[column].wsLlp).round(0) : ''
              break
            case DealerStockViewType.AvgSI:
              text = record[column].finalPoint !== '' ? `${new Decimal(record[column].finalPoint ?? '').round(2)}%` : ''
              break
            case DealerStockViewType.Amount:
              text = record[column].wsAmount !== '' ? new Decimal(record[column].wsAmount ?? '').round(0) : ''
              break
          }
        }
        return h('span', text)
      }
    }
    
    const dataTableCheckboxColumnRender = ({ record }: {record: AdjustmentData}) => {
      const recordCheckState = checkState ? checkState(record) : undefined
      const checkboxComponent = resolveComponent('a-checkbox')
      return h(checkboxComponent, recordCheckState ? {
        checked: recordCheckState.checked,
        'onUpdate:checked': (value: boolean) => {
          recordCheckState.checked = value
        },
        indeterminate: recordCheckState.indeterminate,
        onChange: (checked: boolean) => {
          change(record)
        }
      }: null)
    }

    const tableColumns = computed(() => {
      const columns = [
        {
          slots: { title: 'rowCheckbox' },
          dataIndex: 'annual',
          align: 'center',
          width: 50,
          customRender: dataTableCheckboxColumnRender
        },
        {
          slots: { title: 'vehicle' },
          dataIndex: 'vehicle',
          width: 300,
        },
        {
          dataIndex: 'brand',
          width: 60
        },
        {
          slots: { title: 'annual' },
          dataIndex: 'annual',
          align: 'right',
          width: 120,
          customRender: dataTableColumnRender('annual')
        },
        ...monthes.map((month, index, monthes) => {
          return {
            slots: { title: month },
            dataIndex: month,
            align: 'right',
            width: index === monthes.length - 1 ? 120 : 100,
            customRender: dataTableColumnRender(monthNumberToEn(month) as string)
          }
        })
      ]

      return columns
    })

    const handleChangeBrand = () => {
      const cloneTableData = deepClone(copyTableData[0].children)
      cloneTableData.forEach((row: any, index: any) => {
        if (row.children && row.children.length > 0) {
          row.children = row.children.filter((child: any) => {
            return selectedBrands.value.includes(child.brand)
          })
        }
      })

      for (let i = cloneTableData.length - 1; i >= 0;i--) {
        if (!cloneTableData[i].children || cloneTableData[i].children.length === 0) {
          cloneTableData.splice(i, 1);
        }
      }
      
      tableData.value[0].children = cloneTableData
    }

    const methodsGetBrands = (tableData: any[]) => {
      const brandList: string[] = []
      forEachRecursive(tableData as any[], item => {
        brandList.push(item.brand)
      })
      brands.value = [...new Set(brandList)].filter(item => item)
      selectedBrands.value = brands.value
    }

    const getArInfo = async () => {
      const params = {
        planningId: props.planningId as number,
        kufriVersion: props.kufriVersion as string
      }
      const data = await getfutureDealerStock(params)
      id = data?.headerId
      tableData.value = data.data
      methodsGetBrands(tableData.value) 
      forEachRecursive(tableData.value as any[], item => {
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].finalPoint === null ? '' : item[month].finalPoint.toString()
            item[month].originFinalPoint = item[month].finalPoint
            item[month].wsAmount = item[month].wsAmount === null ? '' : item[month].wsAmount.toString()
            item[month].originWsAmount = item[month].wsAmount
            item[month].editable = true
          }
        })
      })
      useTableTreeDataKey(tableData)
      copyTableData = deepClone(tableData.value)
      nextTick(() => {
        const ele: any | null = document.querySelector(`.dlr-table .ant-table-body`)
        const title: any | null = document.querySelectorAll(`.scroll-content`)
        ele.addEventListener('scroll', function(e: any) {
          title[0].style.left = `-${e.target.scrollLeft}px`
        })
      })
    }

    const expandIconIndex = ref<number>(1)

    const handleChangeRadio = (e: any) => {
      const newValue = e.target.value
      const ele: any | null = document.querySelector('.empty')
    }

    // 点击单元格可编辑 item：单元格数据 record：整行数据
    const handleClickInput = (e: any, month: any) => {
      month.editing = true
      nextTick(()=>{
        const ele: any | null = document.querySelector('.input-area input')
        if (ele) {
          ele.focus()
        }
      })
    }

    const handleFocus = (month: any) => {
      if (month.value === null) {
        return
      }
    }

    const handleBlur = (month: any) => {
      if (month.value === null) {
        month.editing = false
        return
      }
      month.value = month.value * 1
      month.editing = false
      if (selectedRows.value.length > 0) {
        selectedRows.value.forEach((row: any) => {
          row[month.label].finalPoint = month.value
        })
      }
    }
    

    const rowSelection = {
      onChange: (selectedRowKeys: (string | number)[], selecteds: any[]) => {
        selectedRows.value = selecteds
        // handleCheck()
      },
      onSelect: (record: any, selected: boolean, selectedRows: any[]) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected: boolean, selectedRows: any[], changeRows: any[]) => {
        console.log(selected, selectedRows, changeRows);
      },
      columnTitle: ' '
    }

    const handleSave = async () => {
      const checkedCells: any[] = []
      forEachRecursive(tableData.value as AdjustmentData[], item => {
        const recordCheckState = checkState(item)?.checked
        monthesInput.value.forEach(month => {
          if (month.value && recordCheckState) {
            if (!item[month.label]) {
              item[month.label] = {
                planningId: props.planningId,
                kufriVersion: kufriMonth.value,
                brand: item.brand,
                typeClass: item.typeClass,
                year: props.year,
                impactName: item.vehicle,
                month: month.number,
                impactCode: item.impactCode,
                adjustmentStatus: false,
                actualStatus: false
              }
            } 
            item[month.label].finalPoint = month.value
            if (!item.children) {
              checkedCells.push(item[month.label])
            }
          }
        })
      })
      const data = checkedCells.map(item => {
        if (item.dataInfoId) {
          return {
            id: item.dataInfoId,
            adjAvgSi: item.finalPoint
          }
        } else {
          item.adjAvgSi = item.finalPoint
          delete item.finalPoint
          return item
        }
        
      })
      saveAvgSi(data).then(res => {
        message.success('Save Success')
        getArInfo()
      })
    }

    const init = () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }
      getArInfo()
      // getArBrandListAPI(props.planningId, props.kufriVersion, AdjReleaseType.Release).then(data => {
      //   brands.value = data
      //   selectedBrands.value = deepClone(data)
      // }).then(() => {
      //   getArInfo()
      // })
    }

    // watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
    //   if (kufriVersionValue && planningIdValue) {
    //     init()
    //   }
    // }, {
    //   immediate: true
    // })
    const handleExport = async () => {
      const postData = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }
      const res = await featureExport(postData)
      downloadFromStream(res)
    }

    onMounted(() => {
      init()
    })

    const tableSize = elementSize('.dlr-table')

    const tableHeaderSize = elementSize('.dlr-table .ant-table-thead')
    
    return {
      monthesInput,
      moment,
      name,
      brands,
      selectedBrands,
      amount,
      avgSI,
      viewTypes,
      viewType,
      AdjReleaseInputType,
      inputType,
      expandModel,
      monthes,
      rowKey,
      tableColumns,
      tableData,
      actualStatus,
      getArInfo,
      expandedRowKeys,
      handleExpand,
      handleSave,
      handleClickInput,
      handleFocus,
      handleBlur,
      handleChangeRadio,
      handleChangeBrand,
      tableSize,
      tableHeaderSize,
      expandIconIndex,
      rowSelection,
      selectedRows,
      handleExport
    }
  }
})
