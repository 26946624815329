
import { defineComponent, ref, reactive, h, inject, watch } from "vue";
import ExpandMonthTable from "../components/ExpandMonthTable.vue";
import { getReportStructureTree, getReportTable2 } from "@/API/planning";
import TagSelector from "@/views/Planning/components/TagSelector.vue";
import useExpandTableTreeData from "@/hooks/planning/useExpandTableTreeData";
import useTableTreeDataKey from "@/hooks/planning/useTableTreeDataKey";
import deepClone from "@/utils/deepClone";
// import TagSelector from '@/views/Planning/components/TagSelector.vue'
import { forEachRecursive } from "@/utils/planning";
const getStructureChildrenOptions = (list: any) => {
  const result: any[] = [];
  list.children.forEach((child: any) => {
    if (child.children?.length > 0) {
      child.children.forEach((grandson: any) => {
        result.push({
          value: grandson.id,
          label: grandson.itemName,
        });
      });
    } else {
      result.push({
        value: child.id,
        label: child.itemName,
      });
    }
  });
  return result;
};

const col = [
  "annual",
  "seasonOne",
  "seasonTwo",
  "seasonThree",
  "seasonFour",
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const methodFormatList = (list: any[]) => {
  list.forEach((row: any) => {
    for (const key in row) {
      if (col.includes(key)) {
        if (row[key] && (row[key]?.finalPoint || row[key]?.finalPoint === 0)) {
          row[key] = row[key]["finalPoint"].toFixed(2) + "%";
        }
      }
      if (key === "children" && row[key]?.length === 0) {
        row[key] = null;
      }
      if (key === "children" && row[key]) {
        methodFormatList(row[key]);
      }
    }
  });
};

const methodsReduce = (list: any[], key: string) => {
  const obj = {};
  list = list.reduce((cur: any[], next) => {
    obj[next[key]] ? "" : (obj[next[key]] = true && cur.push(next));
    return cur;
  }, []);
  return list;
};
export default defineComponent({
  components: {
    ExpandMonthTable,
    TagSelector,
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    },
  },
  setup(props) {
    const tableData = ref<any[]>([]);
    let copyTableData: any[] = [];

    const rowKey = "key";

    const structureTree = ref<any[]>([]);

    const structureParent = ref<number>(0);
    const structureParentOptions = ref<any[]>([]);

    const structureChildren = ref<any[]>([]);
    const structureChildrenOptions = ref<any[]>([]);

    const brand = ref<any[]>([]);
    const brandOptions = ref<any[]>([]);
    // 表格展开状态
    const expandModel = ref(false);

    const { expandedRowKeys = [], handleExpand } = useExpandTableTreeData(
      tableData as any,
      expandModel,
      "children",
      "key",
      "finalView"
    );

    const methodsGetBrands = (tableData: any[]) => {
      console.log("tableData", tableData);
      brandOptions.value = tableData[0].brandName.split(",");
      brand.value = brandOptions.value;
    };

    const init = async (isCheckBrand = false) => {
      const postData = {
        planningId: props.planningId,
        kufriVersion: props.kufriVersion,
        reportIdList: [] as any[],
        brandList: brand.value,
      };
      if (structureChildren.value?.length > 0) {
        postData.reportIdList = structureChildren.value;
      } else {
        postData.reportIdList = [structureParent.value];
      }
      const res = await getReportTable2(postData);
      tableData.value = res.data;
      useTableTreeDataKey(tableData);
      if (isCheckBrand) {
        methodsGetBrands(tableData.value);
      }
      methodFormatList(tableData.value);
      copyTableData = deepClone(tableData.value);
    };

    const handleStructureChange = () => {
      const tempStructure = structureTree.value.filter((item: any) => {
        return structureParent.value === item.id;
      });
      structureChildrenOptions.value = getStructureChildrenOptions(
        tempStructure[0]
      );
      structureChildren.value = [];
      if (structureChildrenOptions.value?.length === 0) {
        brand.value = [];
        init();
      } else {
        structureChildren.value = structureChildrenOptions.value.map(
          (item: any) => {
            return item.value;
          }
        );
        init();
      }
    };

    const handleStructureChildrenChange = () => {
      init();
    };

    const handleBrandChange = () => {
      if (brand.value.length === 0) {
        return (tableData.value = []);
      }
      init();
    };

    watch(
      [() => props.kufriVersion, () => props.planningId],
      ([kufriVersionValue, planningIdValue]) => {
        console.log(kufriVersionValue, planningIdValue);
        if (kufriVersionValue || planningIdValue) {
          brand.value = [];
          init(true);
        }
      },
      {
        immediate: true,
      }
    );

    const fetchGetStructure = async () => {
      const res = await getReportStructureTree();
      structureTree.value = res;
      structureParentOptions.value = structureTree.value.map((item: any) => {
        return {
          value: item.id,
          label: item.itemName,
        };
      });
      structureParent.value = structureParentOptions.value[0].value;
      structureChildrenOptions.value = getStructureChildrenOptions(
        structureTree.value[0]
      );
      structureChildren.value = structureChildrenOptions.value.map(
        (item: any) => {
          return item.value;
        }
      );
      init(true);
    };
    fetchGetStructure();
    return {
      rowKey,
      tableData,
      structureParent,
      structureParentOptions,
      structureChildren,
      structureChildrenOptions,
      brand,
      brandOptions,
      handleStructureChange,
      handleStructureChildrenChange,
      handleBrandChange,
      expandModel,
      expandedRowKeys,
      handleExpand,
    };
  },
});
