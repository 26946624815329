import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureYear = _resolveComponent("FeatureYear")!
  const _component_CurrentYear = _resolveComponent("CurrentYear")!

  return (_ctx.isFutureYear)
    ? (_openBlock(), _createBlock(_component_FeatureYear, {
        key: 0,
        kufriVersion: _ctx.kufriVersion,
        planningId: _ctx.planningId,
        onHandleUpdateData: _ctx.handleUpdateData,
        year: _ctx.year,
        "kufri-version": _ctx.kufriVersion
      }, null, 8, ["kufriVersion", "planningId", "onHandleUpdateData", "year", "kufri-version"]))
    : (_openBlock(), _createBlock(_component_CurrentYear, {
        key: 1,
        tableData: _ctx.currentYearTableData,
        onHandleUpdateData: _ctx.handleUpdateData,
        onHandleSearchByTargetId: _ctx.handleSearchByTargrtId,
        year: _ctx.year,
        "kufri-version": _ctx.kufriVersion,
        planningId: _ctx.planningId
      }, null, 8, ["tableData", "onHandleUpdateData", "onHandleSearchByTargetId", "year", "kufri-version", "planningId"]))
}