
import { defineComponent, ref, reactive, h, inject, watch } from 'vue'

export default defineComponent({
  props: {
    titleWidth: {
      type: Number
    },
    year: {
      type: String
    }
  },
  setup(props) {
    // 表格展开状态
    const expandModel = ref(false)
    
    const columns = ref<any []>([])
    const handleClickSeason = (type: any, list: any[]) => {
      list.forEach((col: any) => {
        let condition
        if (type === 'seasonOne') {
          condition = col.key === 'january' || col.key === 'february' || col.key === 'march'
          if (col.key === 'seasonOne') {
            col.expandStatus = !col.expandStatus
          }
        } else if (type === 'seasonTwo'){
          condition = col.key === 'april' || col.key === 'may' || col.key === 'june'
          if (col.key === 'seasonTwo') {
            col.expandStatus = !col.expandStatus
          }
        } else if (type === 'seasonThree'){
          condition = col.key === 'july' || col.key === 'august' || col.key === 'september'
          if (col.key === 'seasonThree') {
            col.expandStatus = !col.expandStatus
          }
        } else if (type === 'seasonFour') {
          condition = col.key === 'october' || col.key === 'november' || col.key === 'december'
          if (col.key === 'seasonFour') {
            col.expandStatus = !col.expandStatus
          }
        } 
        if (condition) {
          col.isShow = !col.isShow
        }
      })
      columns.value = list.filter((col: any) => {
        return col.isShow
      }).map((col: any) => {
        return {
          dataIndex: col.dataIndex,
          key: col.key,
          slots: col.slots,
          align: 'right',
          width: col.width || 70
        }
      })
      columns.value.unshift({
        title: 'Vehicle',
        dataIndex: 'rowName',
        key: 'rowName',
        fixed: 'left',
        width: props.titleWidth || 240
      })
    }
    const columnsData = ref<any []>([
      {
        title: 'Annual',
        dataIndex: 'annual',
        key: 'annual',
        isShow: true,
        slots: { 
          customRender: 'annual',
          title: 'annualTitle'
        },
        width: 100
      },
      {
        title: 'Q1',
        dataIndex: 'seasonOne',
        key: 'seasonOne',
        isShow: true,
        expandStatus: false,
        slots: { 
          customRender: 'seasonOne',
          title: 'seasonOneTitle'
        },
        canExpand: true,
        click: (): any => {
          return handleClickSeason('seasonOne', columnsData.value)
        }
      },
      {
        title: `01`,
        dataIndex: 'january',
        key: 'january',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'january',
          title: 'januaryTitle'
        },
      },
      {
        title: '02',
        dataIndex: 'february',
        key: 'february',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'february',
          title: 'FebruaryTitle'
        },
      },
      {
        title: '03',
        dataIndex: 'march',
        key: 'march',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'march',
          title: 'marchTitle'
        }
      },
      {
        title: 'Q2',
        dataIndex: 'seasonTwo',
        key: 'seasonTwo',
        isShow: true,
        expandStatus: false,
        slots: { 
          customRender: 'seasonTwo',
          title: 'seasonTwoTitle'
        },
        canExpand: true,
        click: (): any => {
          return handleClickSeason('seasonTwo', columnsData.value)
        }
      },
      {
        title: '04',
        dataIndex: 'april',
        key: 'april',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'april',
          title: 'aprilTitle'
        },
      },
      {
        title: '05',
        dataIndex: 'may',
        key: 'may',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'may',
          title: 'mayTitle'
        },
      },
      {
        title: '06',
        dataIndex: 'june',
        key: 'june',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'june',
          title: 'juneTitle'
        },
      },
      {
        title: 'Q3',
        dataIndex: 'seasonThree',
        key: 'seasonThree',
        isShow: true,
        expandStatus: false,
        slots: { 
          customRender: 'seasonThree',
          title: 'seasonThreeTitle'
        },
        canExpand: true,
        click: (): any => {
          return handleClickSeason('seasonThree', columnsData.value)
        }
      },
      {
        title: '07',
        dataIndex: 'july',
        key: 'july',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'july',
          title: 'julyTitle'
        },
      },
      {
        title: '08',
        dataIndex: 'august',
        key: 'august',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'august',
          title: 'augustTitle'
        },
      },
      {
        title: '09',
        dataIndex: 'september',
        key: 'september',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'september',
          title: 'septemberTitle'
        },
      },
      {
        title: 'Q4',
        dataIndex: 'seasonFour',
        key: 'seasonFour',
        isShow: true,
        expandStatus: false,
        slots: { 
          customRender: 'seasonFour',
          title: 'seasonFourTitle'
        },
        canExpand: true,
        click: (): any => {
          return handleClickSeason('seasonFour', columnsData.value)
        }
      },
      {
        title: '10',
        dataIndex: 'october',
        key: 'october',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'october',
          title: 'octoberTitle'
        }
      },
      {
        title: '11',
        dataIndex: 'november',
        key: 'november',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'november',
          title: 'novemberTitle'
        }
      },
      {
        title: '12',
        dataIndex: 'december',
        key: 'december',
        isShow: false,
        isMonth: true,
        slots: { 
          customRender: 'december',
          title: 'decemberTitle'
        }
      }
    ])
    const columnsLoop = ref<any []>(columnsData.value)
    columns.value = columnsData.value.filter((col: any) => {
      return col.isShow
    }).map((col: any) => {
      return {
        dataIndex: col.dataIndex,
        key: col.key,
        slots: col.slots,
        align: 'right',
        width: col.width || 70
      }
    })
    columns.value.unshift({
      title: 'Vehicle',
      dataIndex: 'rowName',
      key: 'rowName',
      fixed: 'left',
      width: props.titleWidth || 240
    })
    return {
      expandModel,
      columns,
      columnsLoop
    }
  }
})
