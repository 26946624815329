
import { defineComponent, ref, inject, h } from 'vue'
import type { Ref } from 'vue'
import moment from 'moment'
import Upload from '@/views/Planning/components/Upload.vue'
import { makeKey, kufriKey, planningIdKey, stateStepKey, scenarioIdKey, typePlanningOrSimulationKey, simulationYearKey, planningYearKey } from '@/views/Planning/index.vue'
import { StateStep, CbuWsCategory, KufriInfo } from '@/views/Planning/type'
import { message } from 'ant-design-vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import isExcel from "@/utils/closing/isExcel"
import {
  getPlanningYear as getPlanningYearAPI,
  getKufriList as getKufriListAPI,
  importCbuWs as importCbuWsAPI,
  exportCbuWs as exportCbuWsAPI,
  downloadCbuWsTemplate as downloadCbuWsTemplateAPI,
  getCbuWsUploadInfo as getCbuWsUploadInfoAPI,
  kufriInfo as kufriInfoAPI,
  exportKufri as exportKufriAPI
} from '@/API/planning'
import Decimal from '@/utils/closing/decimal'

interface PlanningYearData {
  year: string;
  id: number;
}
interface UploadResultInfo {
  result: 'none' | 'success' | 'error';
  resultMsg: string;
  exportable: boolean;
}

export default defineComponent({
  components: {
    Upload
  },
  setup() {
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    const planningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const scenarioId = inject<Ref<number>>(scenarioIdKey) as Ref<number>
    const stateStep = inject<Ref<StateStep>>(stateStepKey)
    const simulationYear = inject<Ref<string>>(simulationYearKey) as Ref<string>
    const typePlanningOrSimulation = inject<Ref<string>>(typePlanningOrSimulationKey) as Ref<string>
    const planningYear = inject<Ref<string>>(planningYearKey) as Ref<string>
    let uploadCbuWsCategory = CbuWsCategory.WSVOLUME
    let uploadDataIndex: number | undefined = undefined

    const showUploadDrawer = ref(false)
    const uploadDrawerTitle = ref('')

    const data = ref<(PlanningYearData & { volumeData: UploadResultInfo; llfData: UploadResultInfo })[]>([])

    const lastestKufri = ref('')

    const kufriInfos = ref<(Omit<KufriInfo, 'counts'|'createAt'> & {counts: string; createAt: string})[]>([])

    const init = async () => {
      if (typePlanningOrSimulation.value === 'simulation') {
        data.value = [{
          year: simulationYear.value,
          id: planningId.value,
          volumeData: {
            result: 'none',
            resultMsg: '',
            exportable: false
          },
          llfData: {
            result: 'none',
            resultMsg: '',
            exportable: false
          }
        }]
      } else {
        data.value = ((await getPlanningYearAPI({
          make: make.value,
          scenarioId: scenarioId.value,
          kufriMonth: kufriMonth.value
        })).data as PlanningYearData[])?.map(item => {
          return {
            year: item.year,
            id: item.id,
            volumeData: {
              result: 'none',
              resultMsg: '',
              exportable: false
            },
            llfData: {
              result: 'none',
              resultMsg: '',
              exportable: false
            }
          }
        })
      }
      const {data: kufriList} = await getKufriListAPI({make: make.value})
      if (kufriList) {
        lastestKufri.value = kufriList[0] < kufriMonth.value ? kufriList[0] : kufriMonth.value
      }

      const uploadStatus = new Map<number, {llp: boolean; volume: boolean}>();
      (await getCbuWsUploadInfoAPI(make.value, lastestKufri.value)).forEach(item => {
        const status = {
          llp: false,
          volume: false
        }
        switch (item.category) {
          case 'WS LLP':
            if (item.count > 0) {
              status.llp = true
            }
            break
          case 'WS VOLUME':
            if (item.count > 0) {
              status.volume = true
            }
            break
        }
        uploadStatus.set(item.planning_id, status)
      })

      data.value.forEach(item => {
        const status = uploadStatus.get(item.id)
        if (status?.llp) {
          item.llfData.result = 'success'
          item.llfData.resultMsg = 'Upload Successful'
          item.llfData.exportable = true
        }
        if (status?.volume) {
          item.volumeData.result = 'success'
          item.volumeData.resultMsg = 'Upload Successful'
          item.volumeData.exportable = true
        }
      })

      kufriInfos.value = (await kufriInfoAPI(planningId.value)).filter(item => item.version === lastestKufri.value)
        .map(item => {
          return {
            ...item,
            counts: new Decimal(item.counts).round(0),
            createAt: moment(item.createAt).format('YYYY.MM.DD HH:mm')
          }
        })
    }

    const handleShowUploadDrawer = (category: CbuWsCategory, index: number) => {
      switch (category) {
        case CbuWsCategory.WSVOLUME:
          uploadDrawerTitle.value = 'Upload WS Volume'
          break
        case CbuWsCategory.WSLLP:
          uploadDrawerTitle.value = 'Upload WS LLP'
      }
      uploadCbuWsCategory = category
      uploadDataIndex = index
      showUploadDrawer.value = true
    }

    const handleCalculate = () => {
      if (stateStep) {
        stateStep.value = StateStep.PlanningSimulation
      }
    }

    const handleExport = async (category: CbuWsCategory) => {
        const data = await exportCbuWsAPI(planningId.value, category)
        downloadFromStream(data)
    }

    const handleDownloadTemplate = async() => {
        const data = await downloadCbuWsTemplateAPI(uploadCbuWsCategory)
        downloadFromStream(data)
    }

    const handleUpload = async (file: File, resolve: (value: unknown) => void, reject: (reason?: unknown) => void, options: any) => {
      if (uploadDataIndex === undefined) {
        return
      }
      const params = new FormData()
      params.append('file', file)
      try {
        const fileIsExcel = await isExcel(file)
        if (!fileIsExcel) {
          message.error('file type error')
          throw 'file type error'
        }
        const response = await importCbuWsAPI(make.value, data.value[uploadDataIndex].year, data.value[uploadDataIndex].id, uploadCbuWsCategory, lastestKufri.value, params, {
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
              const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              options.percent.value = percent
            }
          }
        })
        if (response.data.size === 0) {
          resolve(null)
          if (uploadCbuWsCategory === CbuWsCategory.WSVOLUME) {
            data.value[uploadDataIndex].volumeData.result = 'success'
            data.value[uploadDataIndex].volumeData.resultMsg = 'Upload Successful'
            data.value[uploadDataIndex].volumeData.exportable = true
          } else {
            data.value[uploadDataIndex].llfData.result = 'success'
            data.value[uploadDataIndex].llfData.resultMsg = 'Upload Successful'
            data.value[uploadDataIndex].llfData.exportable = true
          }
        } else if (response.data.type === 'application/json') {
          const result = JSON.parse(await response.data.text())
          if (result.code !== '0') {
            throw result.message
          }
        } else {
          downloadFromStream(response)
          throw 'Upload File Error'
        }
      } catch (e) {
        reject(e)
        if (uploadCbuWsCategory === CbuWsCategory.WSVOLUME) {
          data.value[uploadDataIndex].volumeData.result = 'error'
          data.value[uploadDataIndex].volumeData.resultMsg = 'Upload Failure'
        } else {
          data.value[uploadDataIndex].llfData.result = 'error'
          data.value[uploadDataIndex].llfData.resultMsg = 'Upload Failure'
        }
      }
    }

    const handleExportKufri = async (version: string, type: 'RT'|'WS'|'DS') => {
      const res = await exportKufriAPI(make.value, version, planningYear.value, type)
      downloadFromStream(res)
    }

    init()

    return {
      planningYear,
      data,
      CbuWsCategory,
      uploadDrawerTitle,
      showUploadDrawer,
      handleShowUploadDrawer,
      handleUpload,
      handleCalculate,
      lastestKufri,
      handleDownloadTemplate,
      handleExport,
      kufriInfos,
      handleExportKufri
    }
  }
})
