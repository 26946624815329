
import { defineComponent, ref, computed, h, resolveComponent, watch, reactive } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import { AdjReleaseType, AdjReleaseViewType, AdjReleaseInputViewType, AdjReleaseInputType } from '@/views/Planning/type'
import type { Month, TopicData, AdjustmentData, AdjustmentDataInfo } from '@/views/Planning/type'
import Decimal from '@/utils/closing/decimal'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { elementSize } from '@/utils'
import EditableView from '@/views/Planning/components/EditableView.vue' 
import deepClone from '@/utils/deepClone'
import useTableTreeDataKey from '@/hooks/planning/useTableTreeDataKey'
import useTableTreeDataLevel from '@/hooks/planning/useTableTreeDataLevel'
import useExpandTableTreeData from '@/hooks/planning/useExpandTableTreeData'
import useTreeDataRelation from '@/hooks/planning/useTreeDataRelation'
import useCheckTreeData from '@/hooks/planning/useCheckTreeData'
import { monthes, monthesEn, monthNumberToEn, monthEnToNumber, forEachRecursive, numberSubWithPrecision } from '@/utils/planning'
import moment from 'moment'
import { message } from 'ant-design-vue'
import {
  getArTopicList as getArTopicListAPI,
  getArBrandList as getArBrandListAPI,
  getReleaseInfo as getReleaseInfoAPI,
  saveAdjustmentData as saveAdjustmentDataAPI,
  exportRelease as exportReleaseAPI
} from '@/API/planning'

const rowKey = 'key'

export default defineComponent({
  components: {
    TagSelector,
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  emits: [
    'save-data'
  ],
  setup(props, context) {
    let id: number | undefined = undefined

    const name = ref<string>('')
    interface Topic {
      id: number;
      name: string;
    }
    const topics = ref<TopicData[]>([])
    const topic = ref<string>()
    let planningTypeId = 0

    const AdjReleaseViewTypeValue = ref<any>(AdjReleaseViewType)

    const viewTypes = [AdjReleaseViewType.WsWolume, AdjReleaseViewType.WsLLP, AdjReleaseViewType.AvgSI, AdjReleaseViewType.Amount]
    const inputViewTypes = [AdjReleaseInputViewType.AvgSI, AdjReleaseInputViewType.Amount]
    const viewType = ref<AdjReleaseViewType>(AdjReleaseViewType.WsLLP)
    const inputViewType = ref<AdjReleaseInputViewType>(AdjReleaseInputViewType.Amount)
    const inputType = ref<AdjReleaseInputType>(AdjReleaseInputType.Allocation)

    const amount = ref<string>('')
    const avgSI = ref<string>('')

    const expandModel = ref(false)

    // 品牌列表
    const brands = ref<string[]>([])
    const selectedBrands = ref<string[]>([])

    const tableData = ref<AdjustmentData[]>([])

    const monthCheckStatus = reactive(monthes.reduce((status, month) => {
      status[month] = false
      return status
    }, {}) as Record<Month, boolean>)

    const actualStatus = computed(() => {
      const actualStatusMap = reactive(new Map(monthes.map((item: string) => [item, false])))
      if (props.kufriVersion) {
        const kufriMonth = props.kufriVersion.replace('K', '')
        if (kufriMonth) {
          for (let i = 0; i < 12; i++) {
            if (`${props.year}${monthes[i]}` < kufriMonth) {
              actualStatusMap.set(monthes[i], true)
            }
          }
        }
      }
      return actualStatusMap
    })

    useTableTreeDataLevel(tableData)
    const { expandedRowKeys, handleExpand } = useExpandTableTreeData(tableData, expandModel)
    const { parent, children } = useTreeDataRelation(tableData)
    const { checkState, change } = useCheckTreeData(tableData, parent, children)

    const dataTableColumnRender = (column: string) => {
      return ({ record }: {record: AdjustmentData}) => {
        if (inputType.value === AdjReleaseInputType.Input &&
          column !== 'annual' &&
          (([2, 3].includes((record as AdjustmentData & {level: number}).level) &&
              viewType.value === AdjReleaseViewType.AvgSI &&
              inputViewType.value === AdjReleaseInputViewType.AvgSI) ||
            ((record as AdjustmentData & {level: number}).level === 3 &&
              viewType.value === AdjReleaseViewType.Amount &&
              inputViewType.value === AdjReleaseInputViewType.Amount)) &&
          !actualStatus.value.get(monthEnToNumber(column) as string)) {
          const props: Record<string, unknown> = {
            class: 'editable-cell',
            disabled: !record[column].editable,
            onClickOutside: () => {
              if (viewType.value == AdjReleaseViewType.AvgSI) {
                if (record[column].finalPoint !== record[column].originFinalPoint) {
                  if ((record as AdjustmentData & {level: number}).level === 3) { // 修改impactModel层级后typeClass层级不能修改
                    record[column].wsAmount = new Decimal(record[column].wsLlp).times(record[column].finalPoint).div(100).round(15, false)
                    const parentRecord = parent(record)
                    if (parentRecord && parentRecord[column]) {
                      parentRecord[column].editable = false
                    }
                  } else { // 修改typeClass层级后impactModel层级不能修改
                    record[column].wsAmount = new Decimal(record[column].wsLlp).times(record[column].finalPoint).div(100).round(15, false)
                    const childrenRecords = children(record)
                    childrenRecords?.forEach((item) => {
                      item[column].finalPoint = record[column].finalPoint
                      item[column].wsAmount = new Decimal(item[column].wsLlp).times(item[column].finalPoint).div(100).round(15, false)
                      item[column].editable = false
                    })
                  }
                }
              } else {
                if (record[column].wsAmount !== record[column].originWsAmount) {
                  if ((record as AdjustmentData & {level: number}).level === 3) { // 修改impactModel层级后typeClass层级不能修改
                    record[column].finalPoint = record[column].wsLlp ? new Decimal(record[column].wsAmount).div(record[column].wsLlp).times(100).round(15, false) : '0'
                    const parentRecord = parent(record)
                    if (parentRecord && parentRecord[column]) {
                      parentRecord[column].editable = false
                    }
                  } else { // 修改typeClass层级后impactModel层级不能修改
                    record[column].finalPoint = record[column].wsLlp ? new Decimal(record[column].wsAmount).div(record[column].wsLlp).times(100).round(15, false) : '0'
                    const childrenRecords = children(record)
                    childrenRecords?.forEach((item) => {
                      item[column].finalPoint = record[column].finalPoint
                      item[column].wsAmount = new Decimal(item[column].wsLlp).times(item[column].finalPoint).div(100).round(15, false)
                      item[column].editable = false
                    })
                  }
                }
              }
            },
            value: viewType.value == AdjReleaseViewType.AvgSI ? record[column].finalPoint : record[column].wsAmount,
            'onUpdate:value': (value: string) => {
              if (viewType.value == AdjReleaseViewType.AvgSI) {
                record[column].finalPoint = numberSubWithPrecision(value, 15)
              } else {
                record[column].wsAmount = numberSubWithPrecision(value, 15)
              }
            },
            formator: (value: string) => {
              if (viewType.value == AdjReleaseViewType.AvgSI) {
                return value === '' ? '' : `${new Decimal(value).round(2)}%`
              } else {
                return value === '' ? '' : new Decimal(value).round(0)
              }
            } 
          }
          return h(EditableView, props)
        }

        let text = ''
        if (record[column]) {
          switch (viewType.value) {
            case AdjReleaseViewType.WsWolume:
              text = record[column].wsVolume !== null ? new Decimal(record[column].wsVolume).round(0) : ''
              break
            case AdjReleaseViewType.WsLLP:
              text = record[column].wsLlp !== null ? new Decimal(record[column].wsLlp).round(0) : ''
              break
            case AdjReleaseViewType.AvgSI:
              text = record[column].finalPoint !== '' ? `${new Decimal(record[column].finalPoint ?? '').round(2)}%` : ''
              break
            case AdjReleaseViewType.Amount:
              text = record[column].wsAmount !== '' ? new Decimal(record[column].wsAmount ?? '').round(0) : ''
              break
          }
        }
        return h('span', text)
      }
    }


    const dataTableCheckboxColumnRender = ({ record }: {record: AdjustmentData}) => {
      const recordCheckState = checkState ? checkState(record) : undefined
      const checkboxComponent = resolveComponent('a-checkbox')
      return h(checkboxComponent, recordCheckState ? {
        checked: recordCheckState.checked,
        'onUpdate:checked': (value: boolean) => {
          recordCheckState.checked = value
        },
        indeterminate: recordCheckState.indeterminate,
        onChange: (checked: boolean) => {
          change(record)
        }
      }: null)
    }

    const tableColumns = computed(() => {
      const columns = [
        {
          slots: { title: 'vehicle' },
          dataIndex: 'vehicle',
          width: 350,
          fixed: 'left'
        },
        {
          dataIndex: 'brand',
          width: 60,
          fixed: 'left'
        },
        {
          slots: { title: 'annual' },
          dataIndex: 'annual',
          align: 'right',
          width: 130,
          fixed: 'left',
          customRender: dataTableColumnRender('annual')
        },
        ...monthes.map((month, index, monthes) => {
          return {
            slots: { title: month },
            dataIndex: month,
            align: 'right',
            width: index === monthes.length - 1 ? 130 : 110,
            customRender: dataTableColumnRender(monthNumberToEn(month) as string)
          }
        })
      ]

      if (inputType.value === AdjReleaseInputType.Allocation) {
        columns.unshift({
          slots: { title: 'rowCheckbox' },
          dataIndex: 'annual',
          align: 'center',
          width: 50,
          fixed: 'left',
          customRender: dataTableCheckboxColumnRender
        })
      }

      return columns
    })

    const handleChangeInputType = (adjustmentIndex: number) => {
      amount.value = ''
      avgSI.value = ''
      forEachRecursive(tableData.value as AdjustmentData[], item => {
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].originFinalPoint
            item[month].wsAmount = item[month].originWsAmount
          }
        })
      })
      if (inputType.value === AdjReleaseInputType.Allocation) {
        viewType.value = AdjReleaseViewType.WsLLP
      } else {
        viewType.value = AdjReleaseViewType.Amount
        inputViewType.value = AdjReleaseInputViewType.Amount
      }
    }

    const handleChangeViewType = () => {
      if (viewType.value === AdjReleaseViewType.AvgSI) {
        inputViewType.value = AdjReleaseInputViewType.AvgSI
      } else if (viewType.value === AdjReleaseViewType.Amount) {
        inputViewType.value = AdjReleaseInputViewType.Amount
      }
    }

    const handleChangeInputViewType = () => {
      if (inputViewType.value === AdjReleaseInputViewType.AvgSI) {
        viewType.value = AdjReleaseViewType.AvgSI
      } else if (inputViewType.value === AdjReleaseInputViewType.Amount) {
        viewType.value = AdjReleaseViewType.Amount
      }
    }

    const handleAllocation = () => {
      if (amount.value === '') {
        message.error('Please input amount')
        return
      }
      let llp = new Decimal(0)
      const checkedCells: AdjustmentDataInfo[] = []
      forEachRecursive(tableData.value as AdjustmentData[], item => {
        const recordCheckState = checkState(item)?.checked
        monthesEn.forEach(month => {
          if (item[month]) {
            item[month].finalPoint = item[month].originFinalPoint
            item[month].wsAmount = item[month].originWsAmount
          }
          if (recordCheckState && monthCheckStatus[monthEnToNumber(month) as Month]) {
            if ((item as AdjustmentData & {level: number}).level === 3) {
              llp = llp.plus(item[month]?.wsLlp)
            }
            checkedCells.push(item[month])
          }
        })
      })
      const avgSiValue = llp.isZero() ? new Decimal(0) : new Decimal(amount.value).div(llp)
      avgSI.value = `${avgSiValue.times(100).round(2)}%`
      checkedCells.forEach(item => {
        item.finalPoint = avgSiValue.times(100).round(15, false)
        item.wsAmount = new Decimal(item.wsLlp).times(avgSiValue).round(15, false)
      })
    }

    const getArInfo = async () => {
      if (selectedBrands.value.length) {
        const data = await getReleaseInfoAPI(selectedBrands.value, props.planningId as number, props.kufriVersion as string)
        id = data?.headerId
        name.value = data.topicName ?? ''
        topic.value = data.topicCode ? data.topicCode.toString() : undefined
        planningTypeId = data.planningTypeId
        tableData.value = data.children
        forEachRecursive(tableData.value as AdjustmentData[], item => {
          monthesEn.forEach(month => {
            if (item[month]) {
              item[month].finalPoint = item[month].finalPoint === null ? '' : item[month].finalPoint.toString()
              item[month].originFinalPoint = item[month].finalPoint
              item[month].wsAmount = item[month].wsAmount === null ? '' : item[month].wsAmount.toString()
              item[month].originWsAmount = item[month].wsAmount
              item[month].editable = true
            } else {
              item[month] = {
                finalPoint: '',
                originFinalPoint: '',
                wsAmount: '',
                originWsAmount: '',
                wsVolume: null,
                wsLlp: null,
                editable: true
              }
            }
          })
        })
        useTableTreeDataKey(tableData)
      } else {
        tableData.value = []
      }
    }

    const handleChangeAmount = () => {
      amount.value = numberSubWithPrecision(amount.value, 2, 13, true)
    }

    const handleSave = async () => {
      if (name.value === '') {
        message.error('Please input name')
        return
      }
      // if (!topic.value) {
      //   message.error('Please select topic')
      //   return
      // }
      const AdjustmentDataInfos: {
        id: number;
        planningId?: number;
        planningTypeId?: number;
        kufriVersion?: string;
        year?: string;
        month?: string;
        brand?: string;
        typeClass?: string;
        impactCode?: string;
        impactName?: string;
        actualStatus?: boolean;
        finalPoint: number;
        wsAmount: number;
      }[] = []
      forEachRecursive(tableData.value as AdjustmentData[], item => {
        if ((item as AdjustmentData & {level: number}).level === 3) {
          monthesEn.forEach(month => {
            if (item[month] && item[month].finalPoint !== item[month].originFinalPoint) {
              AdjustmentDataInfos.push({
                id: item[month].dataInfoId,
                planningId: props.planningId,
                planningTypeId,
                kufriVersion: props.kufriVersion,
                year: props.year,
                month: monthEnToNumber(month, true),
                brand: item.brand,
                typeClass: item.typeClass,
                impactCode: item.impactCode,
                impactName: item.vehicle,
                actualStatus: actualStatus.value.get(monthEnToNumber(month) as string),
                finalPoint: parseFloat(item[month].finalPoint),
                wsAmount: parseFloat(item[month].wsAmount)
              })
            }
          })
        }
      })
      await saveAdjustmentDataAPI({
        allocationAmount: inputType.value === AdjReleaseInputType.Allocation ?
          parseFloat(amount.value) : undefined,
        allocationType: inputType.value === AdjReleaseInputType.Input ? 0 : 1,
        detailList: AdjustmentDataInfos,
        id,
        topicCode: parseInt(topic.value ?? ''),
        topicName: name.value
      })
      message.success('Save successful')
      context.emit('save-data')
      getArInfo()
    }

    const init = () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }

      getArTopicListAPI(props.planningId as number).then(data => {
        topics.value = data
      })
      getArBrandListAPI(props.planningId, props.kufriVersion, AdjReleaseType.Release).then(data => {
        brands.value = data
        selectedBrands.value = deepClone(data)
      }).then(() => {
        getArInfo()
      })
    }

    watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
      if (kufriVersionValue && planningIdValue) {
        init()
      }
    }, {
      immediate: true
    })

    const tableSize = elementSize('.release-table')

    const tableHeaderSize = elementSize('.release-table .ant-table-thead')

    const handleExport = async () => {
      const postData = {
        brandList: selectedBrands.value,
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }
      const res = await exportReleaseAPI(postData)
      downloadFromStream(res)
    }
    
    return {
      AdjReleaseViewTypeValue,
      moment,
      name,
      topics,
      topic,
      brands,
      selectedBrands,
      amount,
      avgSI,
      viewTypes,
      inputViewTypes,
      viewType,
      inputViewType,
      AdjReleaseInputType,
      inputType,
      expandModel,
      monthes,
      rowKey,
      tableColumns,
      monthCheckStatus,
      tableData,
      actualStatus,
      getArInfo,
      expandedRowKeys,
      handleExpand,
      handleChangeInputType,
      handleChangeViewType,
      handleChangeInputViewType,
      handleAllocation,
      handleChangeAmount,
      handleSave,
      tableSize,
      tableHeaderSize,
      handleExport
    }
  }
})
