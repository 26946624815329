
import { defineComponent, ref, computed, watch } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import deepClone from '@/utils/deepClone'
import type {
  CentralRegionData,
  CentralRegionSpreadJsData
} from '@/views/Planning/type'
import { elementSize } from '@/utils'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { monthes, monthesEn, monthEnToNumber } from '@/utils/planning'
import {
  getCrBrandList as getCrBrandListAPI,
  getCrInfo as getCrInfoAPI,
  exportCr as exportCrAPI
} from '@/API/planning'
import SpreadJsTable from '@/views/Planning/components/SpreadJsTable.vue'
import type { Columns } from '@/views/Planning/components/SpreadJsTable.vue'
import { FormatPercentage } from '@/views/Planning/components/SpreadJsTable.vue'

const rowKey = 'key'

export default defineComponent({
  components: {
    TagSelector,
    SpreadJsTable
  },
  props: {
    year: {
      type: String,
    },
    kufriVersion: {
      type: String,
    },
    planningId: {
      type: Number,
    }
  },
  setup(props) {
    // 品牌列表
    const brands = ref<string[]>([])
    // 选中品牌
    const selectedBrands = ref<string[]>([])

    // 表格展开状态
    const expandModel = ref(false)

     // 表格数据
    const tableData = ref<CentralRegionSpreadJsData[]>([])

    const actualStatus = computed(() => {
      const actualStatusMap = new Map(monthes.map((item: string) => [item, false]))
      if (props.kufriVersion) {
        const kufriMonth = props.kufriVersion.replace('K', '')
        if (kufriMonth) {
          for (let i = 0; i < 12; i++) {
            if (`${props.year}${monthes[i]}` < kufriMonth) {
              actualStatusMap.set(monthes[i], true)
            }
          }
        }
      }
      return actualStatusMap
    })

    const tableColumns = ref<Columns>([])

    // 表格Dom大小
    const tableSize = elementSize('.fleet-table')

    const expandRows = computed(() => {
      if (expandModel.value) {
        return tableData.value.reduce((rows: number[], item, index) => {
          if (item.level === 0 || item.level === 1) {
            rows.push(index)
          }
          return rows
        }, [])
      } else {
        return [0]
      }
    })

    // 查询表格数据
    const getCrInfo = async () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }

      let data: CentralRegionData[] = []
      if (selectedBrands.value.length) {
        data = await getCrInfoAPI(selectedBrands.value, props.planningId, props.kufriVersion)
      }

      tableData.value = []
      data.forEach((make: CentralRegionData) => {
        const makeData = {
          level: 0,
          vehicle: make.vehicle,
          brand: make.brand,
          annual: make.annual?.finalPoint ?? '',
          ...monthesEn.reduce((result, monthEn: string) => {
            result[monthEn] = make[monthEn].finalPoint ?? ''
            return result
          }, {}),
        } as CentralRegionSpreadJsData
        tableData.value.push(makeData)
        const parentRow = tableData.value.length - 1
        const typeClassChildren: number[] = []
        make.children?.forEach((typeClass: CentralRegionData) => {
          const typeClassData = {
            level: 1,
            vehicle: typeClass.vehicle,
            brand: typeClass.brand,
            annual: typeClass.annual?.finalPoint ?? '',
            ...monthesEn.reduce((result, monthEn: string) => {
              result[monthEn] = typeClass[monthEn].finalPoint ?? ''
              return result
            }, {}),
            parentRow: parentRow
          } as CentralRegionSpreadJsData
          tableData.value.push(typeClassData)
          const typeClassRow = tableData.value.length - 1
          typeClassChildren.push(typeClassRow)
          const modelChildren: number[] = []
          typeClass.children?.forEach((model: CentralRegionData) => {
            const impactModelData = {
              level: 2,
              vehicle: model.vehicle,
              brand: model.brand,
              annual: model.annual?.finalPoint ?? '',
              ...monthesEn.reduce((result, monthEn: string) => {
                result[monthEn] = model[monthEn]?.finalPoint ?? ''
                return result
              }, {}),
              parentRow: typeClassRow
            } as CentralRegionSpreadJsData
            tableData.value.push(impactModelData)
            const impactModelRow = tableData.value.length - 1
            modelChildren.push(impactModelRow)
          })
          typeClassData.childrenRow = modelChildren
        })
        makeData.childrenRow = typeClassChildren
      })

      tableColumns.value = [
        {
          title: '',
          children: [
            {
              title: 'Vehicle',
              dataIndex: 'vehicle',
              width: 340,
              align: 'left',
              locked: true,
              fixed: 'left',
            },
            {
              title: '',
              dataIndex: 'brand',
              width: 60,
              locked: true,
              fixed: 'left'
            }
          ]
        },
        {
          title: '',
          children: [
            {
              title: `${props.year}FY`,
              dataIndex: 'annual',
              align: 'right',
              width: 90,
              formatter: new FormatPercentage(),
              locked: true,
              style: {
                backColor: '#CCCCCC',
                cellPadding: '0 5'
              },
              fixed: 'left'
            }
          ]
        },
        // 月份列
        ...monthesEn.map((month, index, monthes) => {
          return {
            title: actualStatus.value.get(monthEnToNumber(month) as string) ? 'Actual' : 'Forecast',
            children: [
              {
                title: `${props.year}-${monthEnToNumber(month)}`,
                dataIndex: month,
                align: 'right' as const,
                width: 90,
                formatter: new FormatPercentage(),
                locked: true,
                style: {
                  backColor: '#CCCCCC',
                  cellPadding: '0 5'
                }
              }
            ]
          }
        })
      ]
    }

    const init = () => {
      if (!props.planningId || !props.kufriVersion) {
        return
      }
      // 获取品牌列表
      getCrBrandListAPI(props.planningId, props.kufriVersion).then((data: string[]) => {
        brands.value = data
        selectedBrands.value = deepClone(data)
      }).then(() => {
        getCrInfo()
      })
    }

    watch([() => props.kufriVersion, () => props.planningId], ([kufriVersionValue, planningIdValue]) => {
      if (kufriVersionValue && planningIdValue) {
        init()
      }
    }, {
      immediate: true
    })

    const handleSearch = () => {
      getCrInfo()
    }

    const handleExport = async () => {
      const postData = {
        brandList: selectedBrands.value,
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }
      const res = await exportCrAPI(postData)
      downloadFromStream(res)
    }

    return {
      brands,
      selectedBrands,
      expandModel,
      handleSearch,
      tableColumns,
      expandRows,
      monthes,
      rowKey,
      tableData,
      actualStatus,
      tableSize,
      handleExport
    }
  }
})
