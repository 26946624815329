
import { defineComponent, ref, reactive, h, inject, watch, computed, Ref } from 'vue'
import { makeKey, kufriKey, planningIdKey } from '@/views/Planning/index.vue'
import { message } from 'ant-design-vue'

export default defineComponent({
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    year: {
      type: String,
      default: '2021'
    },
    kufriVersion: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    const defaultPlanningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const marchCheckbox = ref<boolean>(false)
    const juneCheckbox = ref<boolean>(false)
    const septemberCheckbox = ref<boolean>(false)
    const decemberCheckbox = ref<boolean>(false)

    const selectedRowKeys = ref<any[]>([])

    const selectedRows = ref<any[]>([])

    const postData = ref<any>({})
    
    const handleExpandCol = (child: any, season: any) => {
      child.expandStatus = !child.expandStatus
      season.children[1].isShow = !season.children[1].isShow
      season.children[2].isShow = !season.children[2].isShow
    }

    const computedFilterChildren = computed(() => {
      return (children: any) => {
        return children.filter((child: any) => {
          return child.children
        })
      }
    })

    const methodFormat = (value: any) => {
      if (value === 0) {
        return 0
      } else {
        return Number(value.replaceAll(',', ''))
      }
    }

    const month = parseInt(props.kufriVersion.substring(props.kufriVersion.length - 2))
    
    const kufriYear = parseInt(props.kufriVersion)

    let marchDisabled = false
    let juneDisabled = false 
    let septemberDisabled = false 
    let decemberDisabled = false

    if (kufriYear > parseInt(props.year)) {
      marchDisabled = true
      juneDisabled = true
      septemberDisabled = true
      decemberDisabled = true
    } else if (kufriYear == parseInt(props.year)) {
      if (month <= 3) {
        marchDisabled = false
        juneDisabled = false
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 3 && month <= 6) {
        marchDisabled = true
        juneDisabled = false
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 6 && month <= 9) {
        marchDisabled = true
        juneDisabled = true
        septemberDisabled = false
        decemberDisabled = false
      } else if (month > 9) {
        marchDisabled = true
        juneDisabled = true
        septemberDisabled = true
        decemberDisabled = false
      }
    } else {
      marchDisabled = false
      juneDisabled = false
      septemberDisabled = false
      decemberDisabled = false
    }

    const handleCheck = () => {
      postData.value = {
        planningId: defaultPlanningId.value,
        kufriVersion: props.kufriVersion,
        detailList: []
      }
      const marchIds: number[] = []
      const juneIds: number[] = []
      const septemberIds: number[] = []
      const decemberIds: number[] = []
      const marchDealerStockList: any[] = []
      const juneDealerStockList: any[] = []
      const septemberDealerStockList: any[] = []
      const decemberDealerStockList: any[] = []
      let marchData: any = {}
      let juneData: any = {}
      let septemberData: any = {}
      let decemberData: any = {}
      let marchLlpWsSum = 0
      let juneLlpWsSum = 0
      let septemberLlpWsSum = 0
      let decemberLlpWsSum = 0
      const selectedRowsTemp = selectedRows.value.filter((item: any) => item.model)
      const all: any = props.tableData[0] || []
      selectedRowsTemp.forEach((row: any) => {
        if (marchCheckbox.value) {
          if (row.march.id) {
            marchIds.push(row.march.id)
          } else {
            marchDealerStockList.push({
              planningId: defaultPlanningId.value,
              kufriVersion: props.kufriVersion,
              brand: row.brand,
              typeClass: row.typeClass,
              year: props.year,
              impactName: row.model,
              month: 3,
              impactCode: row.impactCode,
              adjustmentStatus: false,
              actualStatus: marchDisabled,
              openingBalance: methodFormat(row.openingBalance ?? 0),
              allocationAmount: methodFormat(row.stockAdjustment ?? 0)
            })
          }
          marchLlpWsSum += row.march.monthWsLlp ?? 0
          
          marchData = {
            monthWsLlp: marchLlpWsSum,
            amount: methodFormat(all?.stockAdjustment ?? 0) + methodFormat(all?.march?.stockAdjustment ?? 0),
            ids: marchIds,
            dealerStockList: marchDealerStockList
          }
        }
        if (juneCheckbox.value) {
          if (row.june.id) {
            juneIds.push(row.june.id)
          } else {
            juneDealerStockList.push({
              planningId: defaultPlanningId.value,
              kufriVersion: props.kufriVersion,
              brand: row.brand,
              typeClass: row.typeClass,
              year: props.year,
              impactName: row.model,
              month: 6,
              impactCode: row.impactCode,
              adjustmentStatus: false,
              actualStatus: juneDisabled,
              openingBalance: methodFormat(row.openingBalance ?? 0),
              allocationAmount: methodFormat(row.stockAdjustment ?? 0)
            })
          }
          juneLlpWsSum += row.june.monthWsLlp ?? 0
          juneData = {
            monthWsLlp: juneLlpWsSum,
            amount: marchCheckbox.value ? methodFormat(all?.june.stockAdjustment ?? 0) : methodFormat(all?.stockAdjustment ?? 0) + methodFormat(all?.march?.stockAdjustment ?? 0) + methodFormat(all?.june?.stockAdjustment ?? 0),
            ids: juneIds,
            dealerStockList: juneDealerStockList
          }
        }
        if (septemberCheckbox.value) {
          if (row.september.id) {
            septemberIds.push(row.september.id)
          } else {
            septemberDealerStockList.push({
              planningId: defaultPlanningId.value,
              kufriVersion: props.kufriVersion,
              brand: row.brand,
              typeClass: row.typeClass,
              year: props.year,
              impactName: row.model,
              month: 9,
              impactCode: row.impactCode,
              adjustmentStatus: false,
              actualStatus: septemberDisabled,
              openingBalance: methodFormat(row.openingBalance ?? 0),
              allocationAmount: methodFormat(row.stockAdjustment ?? 0)
            })
          }
          septemberLlpWsSum += row.september.monthWsLlp ?? 0
          septemberData = {
            monthWsLlp: septemberLlpWsSum,
            amount: 0,
            ids: septemberIds,
            dealerStockList: septemberDealerStockList
          }
          if (juneCheckbox.value) {
            septemberData.amount = methodFormat(all?.september?.stockAdjustment ?? 0)
          } else {
            if (marchCheckbox.value) {
              septemberData.amount = methodFormat(all?.september?.stockAdjustment ?? 0) + methodFormat(all?.june?.stockAdjustment ?? 0)
            } else {
              septemberData.amount = methodFormat(all?.stockAdjustment ?? 0) + methodFormat(all?.march?.stockAdjustment ?? 0) + methodFormat(all?.june?.stockAdjustment ?? 0) + methodFormat(all?.september?.stockAdjustment ?? 0)
            }
          }
        }
        if (decemberCheckbox.value) {
          if (row.december.id) {
            decemberIds.push(row.december.id)
          } else {
            decemberDealerStockList.push({
              planningId: defaultPlanningId.value,
              kufriVersion: props.kufriVersion,
              brand: row.brand,
              typeClass: row.typeClass,
              year: props.year,
              impactName: row.model,
              month: 12,
              impactCode: row.impactCode,
              adjustmentStatus: false,
              actualStatus: decemberDisabled,
              openingBalance: methodFormat(row.openingBalance ?? 0),
              allocationAmount: methodFormat(row.stockAdjustment ?? 0)
            })
          }
          decemberLlpWsSum += row.december.monthWsLlp ?? 0   
          decemberData = {
            monthWsLlp: decemberLlpWsSum,
            amount: 0,
            ids: decemberIds,
            dealerStockList: decemberDealerStockList
          }
          if (septemberCheckbox.value) {
            decemberData.amount = methodFormat(all?.december?.stockAdjustment ?? 0)
          } else {
            if (juneCheckbox.value) {
              decemberData.amount = methodFormat(all?.december?.stockAdjustment ?? 0) + methodFormat(all?.september?.stockAdjustment ?? 0)
            } else {
              if (marchCheckbox.value) {
                decemberData.amount = methodFormat(all?.december?.stockAdjustment ?? 0) + methodFormat(all?.september?.stockAdjustment ?? 0) + methodFormat(all?.june?.stockAdjustment ?? 0)
              } else {
                decemberData.amount = methodFormat(all?.stockAdjustment ?? 0) + methodFormat(all?.march?.stockAdjustment ?? 0) + methodFormat(all?.june?.stockAdjustment ?? 0) + methodFormat(all?.september?.stockAdjustment ?? 0) + methodFormat(all?.december?.stockAdjustment ?? 0)
              }
            }
          }
        }
      })
      if (marchCheckbox.value) {
        postData.value.detailList.push(marchData)
      }
      if (juneCheckbox.value) {
        postData.value.detailList.push(juneData)
      }
      if (septemberCheckbox.value) {
        postData.value.detailList.push(septemberData)
      }
      if (decemberCheckbox.value) {
        postData.value.detailList.push(decemberData)
      }
    }

    const handleSave = () => {
      handleCheck()
      return postData.value
    }

    const handleClear = () => {
      marchCheckbox.value = false
      juneCheckbox.value = false
      septemberCheckbox.value = false
      decemberCheckbox.value = false

      selectedRowKeys.value = []
      selectedRows.value = []

      postData.value = {
        planningId: defaultPlanningId.value,
        kufriVersion: props.kufriVersion,
        detailList: []
      }
    }

    const getCheckboxProps = (record: any) => {
      return ({
        props: {
          disabled: true,
          defaultChecked: record.brand == 'CBU',
        }
      })
    }

    const onSelect = (record: any, selected: boolean) => {
      if (record.id == 0) {
        if (selected) {
          selectedRowKeys.value = props.tableData?.map((item: any) => item.id) || []
          selectedRows.value = props.tableData || []
        } else {
          selectedRowKeys.value = []
          selectedRows.value = []
        } 
      } else {
        if (selected) {
          if (selectedRowKeys.value.length == props.tableData?.length as any - 1) {
            selectedRowKeys.value.push(0)
          }
        } else {
          const allIndex = selectedRowKeys.value.indexOf(0)
          if (allIndex > -1) {
            selectedRowKeys.value.splice(allIndex, 1)
          }
        }
      }
    }

    const onChange =  (keys: (string | number)[], selecteds: any[]) => {
      selectedRowKeys.value = keys
      selectedRows.value = selecteds
      handleCheck()
    }

    selectedRowKeys.value = props.tableData?.map((item: any) => item.id) || []
    selectedRows.value = props.tableData || []

    const viewHeight = document.documentElement.clientHeight

    const allInfo: any = props.tableData[0] || {}
    if (allInfo?.march?.absAmount) {
      marchCheckbox.value = true
    }
    if (allInfo?.june?.absAmount) {
      juneCheckbox.value = true
    }
    if (allInfo?.september?.absAmount) {
      septemberCheckbox.value = true
    }
    if (allInfo?.december?.absAmount) {
      decemberCheckbox.value = true
    }
    
    return {
      marchCheckbox,
      juneCheckbox,
      septemberCheckbox,
      decemberCheckbox,
      computedFilterChildren,
      handleExpandCol,
      handleCheck,
      handleSave,
      handleClear,
      viewHeight,
      getCheckboxProps,
      onChange,
      selectedRowKeys,
      onSelect,
      make
    }
  }
})
