import GC from '@grapecity/spread-sheets'

export interface EventHandlers {
  processMouseDown?: () => void;
  processMouseUp?: () => void;
}

export class ImageButtomColumnHeader extends GC.Spread.Sheets.CellTypes.ColumnHeader {
  private imageSrc = ''
  private tipElement: HTMLElement | null = null
  private tip: string | undefined = undefined
  private eventHandlers: EventHandlers | undefined = undefined

  constructor(imageSrc: string, tip?: string, eventHandlers?: EventHandlers) {
    super()
    this.imageSrc = imageSrc
    this.tip = tip
    this.eventHandlers = eventHandlers
  }

  private showTip(container: HTMLElement, top: number, left: number, tip: string) {
    if (!this.tipElement) {
      const span = document.createElement('span')
      span.style.position = 'absolute'
      span.style.background = '#EEEEEE'
      span.style.border = '1px solid black'
      span.style.boxShadow = '5px 5px 5px rgba(0,0,0,0.4)'
      span.style.paddingLeft = '5px'
      span.style.paddingRight = '5px'
      span.style.fontSize = '14px'
      container.insertBefore(span, null)
      this.tipElement = span
    }
    const tipElement = this.tipElement
    tipElement.textContent = tip
    const spanStyle = tipElement.style
    spanStyle.top = top + 'px'
    spanStyle.left = left + 'px'
  }
    
  private hideTip(container: HTMLElement) {
    if (this.tipElement) {
      container.removeChild(this.tipElement)
      this.tipElement = null
    }
  }

  paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style,  context?: any) {
    super.paint(ctx, value, x, y, w, h, style, context)
    ctx.save();
    const image = new Image()
    image.src = this.imageSrc
    ctx.textAlign = 'center'
    ctx.drawImage(image, x + 5, y + (h - 14) / 2, 14, 14)
    ctx.restore()
  }

  getHitInfo(x: number, y: number, cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
    return {x: x, y: y, row: context.row, col: context.col, cellRect: cellRect, sheetArea: context.sheetArea}
  }

  processMouseMove(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
    const sheet = (hitInfo as {sheet: any}).sheet
    if (this.tip) {
      this.showTip(sheet.getParent().getHost(), (hitInfo.y || 0), (hitInfo.x || 0) + 10, this.tip)
    }
    return true
  }

  processMouseLeave(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
    const sheet = (hitInfo as {sheet: any}).sheet
    this.hideTip(sheet.getParent().getHost())
    return true
  }

  processMouseDown(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
    const sheet = (hitInfo as {sheet: any}).sheet
    this.hideTip(sheet.getParent().getHost())
    if (this.eventHandlers?.processMouseDown) {
      this.eventHandlers.processMouseDown()
    }
    return true
  }

  processMouseUp(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
    const sheet = (hitInfo as {sheet: any}).sheet
    this.hideTip(sheet.getParent().getHost())
    if (this.eventHandlers?.processMouseUp) {
      this.eventHandlers.processMouseUp()
    }
    return true
  }
}