
import { defineComponent, ref, reactive, h, inject, watch } from 'vue'
import type { Ref } from 'vue'
import TagSelector from '@/views/Planning/components/TagSelector.vue'
import tableFirst from '../Planning/FinalView/tableFirst.vue'
import tableSecond from '@/views/Planning/FinalView/tableSecond.vue'
import tableThird from '@/views/Planning/FinalView/tableThird.vue'
import { message } from 'ant-design-vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import moment from 'moment'
import { makeKey, scenarioIdKey, kufriKey, createMonthKey, currentKufriKey, simulationYearKey, typePlanningOrSimulationKey, planningIdKey } from '@/views/Planning/index.vue'
import {
  getPlanningYear as getPlanningYearAPI,
  lockSimulation,
  exportFinal as exportFinalAPI
} from '@/API/planning'
interface PlanningYear {
  year: string;
  id: number;
  createMonth: string;
  currentKufri: string;
  kufriMonth: string;
}
function generateKufirVersions(begin: string, end: string) {
  const versions = []
  const month = moment(begin.replace('K', ''))
  const endMonth = moment(end.replace('K', ''))
  while (!month.isAfter(endMonth)) {
    versions.push(month.format('YYYYKMM'))
    month.add(1, 'M')
  }

  return versions
}
export default defineComponent({
  components: {
    TagSelector,
    tableFirst,
    tableSecond,
    tableThird
  },
  setup() {
    const activeKey = ref<string>('1')
    const make = inject<Ref<string>>(makeKey) as Ref<string>
    const years = ref<string[]>([])
    const year = ref<string>()
    const scenarioId = inject<Ref<number>>(scenarioIdKey) as Ref<number>
    const kufriMonth = inject<Ref<string>>(kufriKey) as Ref<string>
    const defaultPlanningId = inject<Ref<number>>(planningIdKey) as Ref<number>
    const typePlanningOrSimulation = inject<Ref<string>>(typePlanningOrSimulationKey) as Ref<string>
    const createMonth = inject<Ref<string>>(createMonthKey) as Ref<string>
    const currentKufri = inject<Ref<string>>(currentKufriKey) as Ref<string>
    const simulationYear = inject<Ref<string>>(simulationYearKey) as Ref<string>
    const kufris = ref<string[]>()
    const kufri = ref<string>()
    const planningId = ref<number>()
    let yearPlanningIdMap = new Map<string, number>()

    watch([year, kufri] as const, ([yearValue]) => {
      if (typePlanningOrSimulation.value === 'simulation') {
        planningId.value = defaultPlanningId.value
      } else {
        planningId.value = yearValue ? yearPlanningIdMap.get(yearValue): undefined
      }
    })

    const getPlanningYearInfo = () => {
      getPlanningYearAPI({
        make: make.value,
        scenarioId: scenarioId.value,
        kufriMonth: kufriMonth.value
      }).then((data: {data: PlanningYear[]}) => {
        yearPlanningIdMap = new Map()
        if (data.data.length) {
          data.data.forEach((item: PlanningYear) => {
            if (item.year) {
              yearPlanningIdMap.set(item.year, item.id)
            }
          })

          years.value = Array.from(yearPlanningIdMap.keys()).sort()
          if (years.value.length) {
            year.value = years.value[0]
          }
          const kufriVersionStart = data.data[0].createMonth
          const kufriVersionEnd = data.data[0].currentKufri
          kufris.value = generateKufirVersions(kufriVersionStart, kufriVersionEnd)
          kufri.value = data.data[0].currentKufri
        }
      })
    }

    const getKufriAndYear = () => {
      planningId.value = defaultPlanningId.value
      const kufriVersionStart = createMonth.value
      const KufriVersionEnd = currentKufri.value
      kufris.value = generateKufirVersions(kufriVersionStart, KufriVersionEnd)
      kufri.value = kufris.value[kufris.value.length - 1]
      years.value = [simulationYear.value]
      year.value = simulationYear.value
    }

    const handleSearch = () => {
      if (typePlanningOrSimulation.value === 'simulation') {
        // 无意义
        getPlanningYearAPI({
          make: make.value,
          scenarioId: scenarioId.value,
          kufriMonth: kufriMonth.value
        })
      } else {
        getPlanningYearInfo()
      }
    }

    if (typePlanningOrSimulation.value === 'simulation') {
      getKufriAndYear()
    } else {
      getPlanningYearInfo()
    }

    const handleSaveFinalVersion = () => {
      const data = {
        planningId: planningId.value,
        kufriVersion: kufri.value
      }
      lockSimulation(data).then(() => {
        message.success('Save Success.')
      })
    }

    const handleExport = async () => {
      const postData = {
        planningId: planningId.value,
        kufriVersion: kufri.value
      }
      const res = await exportFinalAPI(postData)
      downloadFromStream(res)
    }

    return {
      activeKey,
      years,
      year,
      kufris,
      kufri,
      planningId,
      typePlanningOrSimulation,
      handleSearch,
      handleSaveFinalVersion,
      handleExport
    }
  }
})
