import type { Ref } from 'vue'
import { computed } from 'vue'

type Data = Partial<Record<string, Data[]|unknown>>

function getParentRealtion<T extends Data>(data: T[], parent: T | null, childrenKey = 'children', relation: Map<T, T | null>) {
  data.forEach(item => {
    relation.set(item, parent)
    if ((item[childrenKey] as T[])?.length) {
      getParentRealtion(item[childrenKey] as T[], item, childrenKey, relation)
    }
  })
}

export default function<T extends Data>(data: Ref<T[]>, childrenKey = 'children') {

  const parentRelation = computed(() => { 
    const relation = new Map<T, T | null>()
    getParentRealtion(data.value, null, childrenKey, relation)
    return relation
  })

  return {
    children(p: T) {
      return (p[childrenKey] as T[])?.length ? p[childrenKey] as T[] : null
    },
    parent(c: T) {
      return parentRelation.value.get(c) ?? null
    },
    parents(c: T) {
      const parents = []
      let t = this.parent(c)
      while (t) {
        parents.push(t)
        t = this.parent(c)
      }

      return parents
    }
  }
}